.Cashier-header-addressList {
  display: flex;
  justify-content: left;
  height: 2rem;
  overflow: visible;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;

}
p{
  margin:0!important;
}

.showAddress-select-Option-none{
  display: none;
}
.Show-Address-header{
  display: flex;
  background-color: #f7f7f7;
  flex-direction: row;
  padding-inline: 2vw;
  justify-content: left!important;
  align-items: center!important;

}

.Show-Address-Title{
  display: flex;
  font-size: 1rem;
  height: 2rem;
  font-family: 'Sweet Sans Pro';
  color: #137CC1;
  line-height: 2rem;
}

select::-webkit-scrollbar {
  display: none;
}

.Show-Address-dropIcon{
  font-size: 1rem;
  margin-left: 0.6rem;
  color: #137CC1;
}

.Show-Address-content{
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  align-items: center;
  z-index: 999;
  border-radius: 0 0 17px 17px;
  position: relative;
  margin-top: -0.1rem;
  padding-top: 0.7rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.06);
  min-height:max-content;
}
.Show-Address-buttons{
  display: flex;
  flex-direction: row;
  width: 100vw;
  z-index: 999999999;
  justify-content: space-between;
  padding-inline: 15vw;
  }
.Show-Address-button{
  display: flex;
  min-width: 8rem!important;
  justify-content: center;  z-index: 999999999;
  font-weight: 400;
  height: 2rem;
  border-radius: 47px;
  font-size: 1.2rem;
  background-color: #137CC1;
  color: #FFFFFF;
  font-family: 'Sweet Sans Pro';
}
.Show-Address-button-active {
  display: flex;
  min-width: 8rem!important;
  justify-content: center;
  font-weight: 400;
  height: 2rem;
  border-radius: 47px;
  font-size: 1.2rem;
  background-color: #FFFFFF;
  color: #137CC1;
  border: 1px solid #137CC1;
  font-family: 'Sweet Sans Pro';
}

select {
  /* Reset Select */

  outline: 0;
  border: 0;
  box-shadow: none;
  /* Personalize */


  max-width: 10rem !important;
  color: #fff;
  cursor: pointer;
}
option{
  cursor: pointer;
}
/* Custom Select wrapper */
.showAddress-select {
  position: relative;
  border-radius: 12px!important;
  font-family: 'Sweet Sans Pro';
  text-transform: capitalize;
  display: flex;
  max-width: 12rem !important;
  text-align: left;
  height: 2.5rem!important;
  font-size: 1.1rem;
  line-height: 1rem;
  border-radius: .25em;
  background-color: white;
  border: 1px solid #137CC1;
  color: #137CC1;
  margin-block: 0.5rem;
  overflow: hidden;
}
/* Arrow */
.showAddress-select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  max-width: 8rem !important;
  padding: 1em;
  background-color: red;
  transition: .25s all ease;
  pointer-events: none;
}
/* Transition */
.showAddress-select:hover::after {
  color: #f39c12;
}

.Address-Switch{
  background-color: #137CC1;
}


.addAddress-Button-Container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.Cashier-header-addressList-Container{
  padding-bolck: 2px;
  margin-bottom: 0.5rem;
  margin-top: 1px;
  display: flex;
  flex-direction: column;

}



.Cashier-header-button-add {
  margin-bottom: 0.43rem;
  border: none !important;
  color: #00704A !important;
}

.Cashier-header-button-add:hover {
  background-color: #00704A !important;
}

.pickup-form{
  align-self: baseline;
  background-color: #FFFFFF;
  z-index: 3;
}

.pac-container{
  z-index: 999999;
}

.shippmentContainer{
  top:0;
  z-index: 999;
  width: 100%;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5vh;
  height: 150%!important;
  position: absolute;
  background-color: rgba(107, 107, 107, 0.35);
}

.AddAddress-form{
  height: 90vh;
  width: 90vw;
  background-color: #FFFFFF;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding-inline: 5vw;
}
.ant-notification-notice ant-notification-notice-info ant-notification-notice-closable{
  z-index: 9999;
}
.map {
  width: 100%;
  height: 350px;
  margin-block: 10px;
  margin-bottom: 3rem;
}

.AddAddress-form-input-margin{
  width: 100%;
  background-color: #4ba731;
  height: 1.8rem;
}

.AddAddress-Form-phone-input{
  border: 1px solid #000;
}

.shipment-form-input{
  background-color: #4a82ff;
}

.Show-Address-storeAlert{
  position: absolute;
  box-shadow: 0 2px 4px rgba(1, 1, 1, 0.48), 0 2px 2px rgba(0, 0, 0, 0.06);
  width: 80vw;
  border-radius: 8px;
  height: 20vh;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99999;
  top:10vh;
  left: 10vw;
  justify-content: center;
}
.Show-Address-storeAlert-close{
  color: #4a82ff;
  position: absolute;
  right: 1rem;
  top:1rem
}
.Show-Address-storeAlert-text{
  font-family: 'Sweet Sans Pro';
  color: rgba(0, 0, 0, 0.85);
  width: 60%;
  text-align: center;
  line-height: 1rem;
}
.Show-Address-storeAlert-Button{
  display: flex;
  min-width: 5rem!important;
  justify-content: center;
  font-weight: 400;
  height: 1.6rem;
  align-items: center;
  border-radius: 12px;
  font-size: 1rem;
  background-color: #FFFFFF;
  color: #137CC1;
  border: 1px solid #137CC1;
  font-family: 'Sweet Sans Pro';
  margin-top: 1rem;
}
.showAddress-select-Option{
  width: 100%;
  background-color: #FFFFFF;
  height: 2rem;
}

.AddAddress-product-Purchase {
  text-align: center;
  display: flex;
  align-self: center;
  font-size: 1.1rem;
  align-items: center;
  font-family: 'Sweet Sans Pro';
  background-color: #137CC1;
  margin: 0.25rem 0px 0px 0px;
  width: 6rem;
  height: 2rem;
  justify-content: center;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 47px;
  border: 0;
}
.AddAddress-product-Purchase:disabled{
  background-color: grey;
}


@media only screen and (min-width: 800px) {
  .Cashier-header-addressList {

    width: 25vw!important;


  }
  .Show-Address-storeAlert{
    width: 25vw;
    left: 1rem;
    top: 8vh;
  }
  .Show-Address-header{
    width: 25vw!important;
  }
  .Show-Address-content{
    width: 25vw!important;
  }
  .Show-Address-buttons{
    width: 25vw!important;
    padding-inline: 1rem;
  }
}