.editing {
  align-items: center;
  flex-direction: column;
}

._hj-NS6la__EmotionIconDefault__commentIcon {
  display: none;
}
.transaction-status-table-max{
  margin-block: 1rem ;
}
.Editing-Circle-Button {
  height: 2rem;
  margin-top: 0.3rem;
  display: flex;
  color: #6b6b6b;
  cursor: pointer;
  width: max-content;
  margin-inline: 6px;
  border-radius: 20px;
  align-items: center;
  padding-inline: 10px;
  background-color: #ededed;
  min-width: max-content !important;
}

.Editing-Circle-Button-Selected {
  height: 2rem;
  border: solid;
  margin-top: 0.3rem;
  display: flex;
  color: #F12535;
  cursor: pointer;
  margin-inline: 6px;
  width: max-content;
  border-radius: 20px;
  align-items: center;
  padding-inline: 10px;
  justify-content: center;
  background-color: #ededed;
  border-width: 2px !important;
  border-color: #F12535;
  min-width: max-content !important;
}

.Editing-Circle-Button-Container {
  display: flex;
  max-width: 90vw;
  flex-direction: row;
  padding-bottom: 6px;
  white-space: nowrap;
  scrollbar-width: none;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scroll-snap-type: x mandatory;
  justify-content: space-between;
}

.Editing-Circle-Button-mobile-Container {
  display: flex;
  width: 80vw;
  flex-direction: row;
  padding-bottom: 6px;
  margin-block: 5px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scroll-snap-type: x mandatory;
  justify-content: space-between;
}

.Editing-Circle-Button-mobile-Container1 {
  display: flex;
  width: 90vw;
  flex-direction: row;
  padding-bottom: 6px;

  overflow-x: scroll;
  -ms-overflow-style: none;
  scroll-snap-type: x mandatory;
  justify-content: space-between;
}

.header-editing {
  width: 40%;
  margin: auto;
  display: flex;
  padding-inline: 3%;
  flex-direction: row;
  align-content: center;
  justify-content: right;
  background-color: transparent;
  border-bottom-color: transparent;
}

.editing .ant-form-item-label > label {
  font-size: 16px;
}

.Editing-breadcrumb-left-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 2px;
  height: min-content;
  justify-content: flex-start;
  overflow: hidden;
  min-width: 1.5rem !important;
}

.editing-breadcrumb-home-icon {
  color: #137CC1;
  cursor: pointer;
  margin-left: 0.5rem;
  font-size: 1.2rem !important;
}

.editing .ant-form-item-label > label::after {
  content: none;
}

.editing .ant-radio-group {
  display: inline-flex;
}

.editing .ant-radio-button-wrapper {
  min-width: 135px;
  text-align: center;
}

.editing [type="radio"] + span:before, [type="radio"] + span:after {
  content: none;
}

.Form .ant-alert {
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  width: 70%;
}

.ant-row {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.editingSearchContent {
  display: flex;
  flex-direction: column;
}

.Editing-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #137CC1;
  height: 4rem;
  padding-inline: 1rem;
}

.Max-logo {
  width: 10rem;
}

.Max-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #0F1D3C;
  height: 4rem;
  padding-inline: 1rem;
}

.Max-header-subContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Max-price-detail {
  color: #EBA039;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  margin-block: 1rem!important;
  font-family: 'Sweet Sans Pro';
}

.Max-Method-detail {
  color: #0F1D3C;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-top: 2rem!important;
  font-weight: bold;
  text-align: center;
  font-family: 'Sweet Sans Pro';

}

.Max-header-container-search {
  display: flex;
  flex-direction: row;

  background-color: #0F1D3C;
  height: 3.1rem;
  margin-top: -1px;
  padding-inline: 2rem;
}

.Editing-header-subContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 6rem;
  justify-content: space-between;
}


.AMPM-pay-header {
  padding-left: 1rem;
  padding-top: 0.2rem;
}

.AMPM-pay-header-subtitle {
  color: #137CC1;
  font-family: 'Sweet Sans Pro';
  font-size: 1.2rem;

}

.AMPM-pay-paginator {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.8rem;
}

.AMPM-pay-header-amount {
  color: #137CC1;
  font-family: 'Sweet Sans Pro';
  font-size: 3rem;
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin-bottom: 2rem!important;
}

.AMPM-pay-paginator-left {
  color: #137CC1;
  font-size: 1.1rem;

}

.AMPM-pay-paginator-text {
  color: #137CC1;
  font-family: 'Sweet Sans Pro';
  font-size: 1.2rem;

}


@media screen and (min-width: 769px) {
  .AMPM-pay-header-subtitle {
    text-align: center;
    width: 100%;
  }

  body {
    overflow-y: scroll;
  }

  body::-webkit-scrollbar {
    width: 8px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: #137CC1;
    border-radius: 0 0 4px 4px;
  }

  body::-webkit-scrollbar-thumb:hover {
    background-color: #333;
  }

}

.editingOrderCashier {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 10px;
  height: 2rem;


}

.Editing-header-Search {
  color: #b9b9b9;
  font-size: 1.8rem;
  margin-right: 1rem;
}

.Editing-logo {
  width: auto;
  height: 3rem;
}

.new-header-search-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-inline: 3rem;
  position: relative;
}

.Cashier-breadcrumb-span {
  cursor: pointer;
}

.print-view {
  height: 100% !important;
  overflow: auto !important;
}

html, body {
  height: initial !important;
  overflow: initial !important;
}

.AMPMfoot{
  width: 2rem;
  height: 2rem;
  display: flex;
}