
.skeletonBillingInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.skeletonBilling {
    width: 40%;
    display: flex;
    justify-content: center;
}

.skeletonBillingPrice {
    width: 40%;
    display: flex;
    margin: 1rem 0;
    justify-content: center;
}

.skeletonBillingForm > span {
    margin-top: 1rem;
    width: 300px !important;
    height: 450px !important;
}

@media only screen and (min-width: 768px) {
    .skeletonBillingForm > span{
        width: 700px !important;
        height: 450px !important;
    }
}

@media only screen and (min-width: 1024px) {
    .skeletonBillingForm > span{
        width: 1000px !important;
        height: 450px !important;
    }
}