.Cashier-item-adding {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Cashier-item-adding .Cashier-item-adding-btn {
  color: #4a82ff;
  border: none !important;
  background-color: #ffffff;
}

.Cashier-item-adding .btn-floating {
  box-shadow: none;
  background-color: #ffffff;
  margin-inline: 0.5rem;
}

.Cashier-item-adding .btn-floating[disabled] {
  border: none !important;
  background-color: #ffffff !important;
  margin-inline: 0.5rem;
}

.Cashier-item-adding .Cashier-item-adding-btn:hover, .Cashier-item-adding-btn:focus {
  border: none !important;
  background-color: #ffffff !important;
}

.Cashier-item-adding .btn-floating i {
  color: #4a82ff;
  font-size: 2rem !important;
}

.Cashier-item-adding .btn-floating[disabled] i {
  color: #9F9F9F !important;
}

.Cashier-item-adding .btn-floating[disabled] i  {
  color: #ffffff;
}


@media only screen and (max-width: 600px) {
  .Cashier-item-adding .btn-floating i {
    color: #4a82ff;
    font-size: 1rem !important;
  }
}
