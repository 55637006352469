.Editing-item-adding {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Editing-item-adding .Max-item-adding-btn {
  color: #0F1D3C;
  border: none !important;
  background-color: #ffffff;
}

.Editing-item-adding   {
  box-shadow: none;
  background-color: #ffffff;
}

.Editing-item-adding .Cashier-item-adding-btn {
  color: #0F1D3C;
  border: none !important;
  background-color: #ffffff;
}

.Editing-item-adding .btn-floating {
  box-shadow: none;
  background-color: #ffffff;
  color: #0F1D3C;
}

.Editing-item-adding .btn-floating[disabled] {
  border: none !important;
  background-color: #ffffff !important;
  color: #0F1D3C;

}


.max-cart-add-but{
  border-radius: 50%;
  box-shadow: none;
  height: 1.5rem;
  width: 1.5rem;
  color: #0F1D3C;
  display: flex;
  background-color:  transparent!important;

  font-weight: 700;
  font-size: 2rem;
  justify-content: center;

  align-items: center;
  padding-bottom: 0.2rem;
}
.Max-cart-quantity{
  color: #000;
  font-size: 1.2rem;
  margin-bottom: 0.4rem;
}


.Max-header-cart-modal-product-addg {
  display: flex;
  align-self: center;
  height: 2rem;
  background-color: #FFFFFF;
  padding-inline: 1rem;
  border-radius: 12px;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  min-width: 6rem;

}
.Editing-item-adding  [disabled] {
  border: none !important;
  background-color: #ffffff !important;
}

.Editing-item-adding .Max-item-adding-btn:hover, .Max-item-adding-btn:focus {
  border: none !important;
  background-color: #ffffff !important;
}

.Editing-item-adding   i {
  color: #0F1D3C;
  font-size: 2rem !important;
}

.Editing-item-adding  [disabled] i {
  color: #9F9F9F !important;
}

.Editing-item-adding  [disabled] i  {
  color: #ffffff;
}
.Max-cart-control-cont2{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 6rem;
  align-items: center;

}
.Max-cart-control-cont{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  min-width: 6rem;
  align-items: center;
  margin-bottom: -10px!important;
}
.Max-cart-control-text2{
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
  font-size: 0.9rem;
  margin-top: -10px;
}
.Max-cart-control-text{
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
  font-size: 1rem;
  margin-top: 12px;
  text-align: center;
  cursor: pointer;
}
.Max-cart-control-text::-webkit-inner-spin-button,
.Max-cart-control-text::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Max-cart-control-text {
  -moz-appearance: textfield;
}

.Max-cart-control-icon{
  font-size: 1.2rem;
  color: #0F1D3C!important;
  background-color: #FFFFFF;
  border-radius: 50%;

  padding: 3px;
}