@import url(https://fonts.cdnfonts.com/css/sweet-sans-pro);
@import url(https://fonts.cdnfonts.com/css/sweet-sans-pro);
@import url(https://fonts.cdnfonts.com/css/sweet-sans-pro);
@import url(https://fonts.cdnfonts.com/css/sweet-sans-pro);
.skeletonHeader{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    height: 81px;
}
.skeletonHeader > div > div{
    height: 57px !important;
}
.SkeletonButton{
    margin-top: 0.7rem;
}
.editing {
  align-items: center;
  flex-direction: column;
}

._hj-NS6la__EmotionIconDefault__commentIcon {
  display: none;
}
.transaction-status-table-max{
  margin-block: 1rem ;
}
.Editing-Circle-Button {
  height: 2rem;
  margin-top: 0.3rem;
  display: flex;
  color: #6b6b6b;
  cursor: pointer;
  width: -webkit-max-content;
  width: max-content;
  margin-inline: 6px;
  border-radius: 20px;
  align-items: center;
  padding-inline: 10px;
  background-color: #ededed;
  min-width: -webkit-max-content !important;
  min-width: max-content !important;
}

.Editing-Circle-Button-Selected {
  height: 2rem;
  border: solid;
  margin-top: 0.3rem;
  display: flex;
  color: #F12535;
  cursor: pointer;
  margin-inline: 6px;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 20px;
  align-items: center;
  padding-inline: 10px;
  justify-content: center;
  background-color: #ededed;
  border-width: 2px !important;
  border-color: #F12535;
  min-width: -webkit-max-content !important;
  min-width: max-content !important;
}

.Editing-Circle-Button-Container {
  display: flex;
  max-width: 90vw;
  flex-direction: row;
  padding-bottom: 6px;
  white-space: nowrap;
  scrollbar-width: none;
  overflow-x: hidden;
  -ms-overflow-style: none;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  justify-content: space-between;
}

.Editing-Circle-Button-mobile-Container {
  display: flex;
  width: 80vw;
  flex-direction: row;
  padding-bottom: 6px;
  margin-block: 5px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  justify-content: space-between;
}

.Editing-Circle-Button-mobile-Container1 {
  display: flex;
  width: 90vw;
  flex-direction: row;
  padding-bottom: 6px;

  overflow-x: scroll;
  -ms-overflow-style: none;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  justify-content: space-between;
}

.header-editing {
  width: 40%;
  margin: auto;
  display: flex;
  padding-inline: 3%;
  flex-direction: row;
  align-content: center;
  justify-content: right;
  background-color: transparent;
  border-bottom-color: transparent;
}

.editing .ant-form-item-label > label {
  font-size: 16px;
}

.Editing-breadcrumb-left-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 2px;
  height: -webkit-min-content;
  height: min-content;
  justify-content: flex-start;
  overflow: hidden;
  min-width: 1.5rem !important;
}

.editing-breadcrumb-home-icon {
  color: #137CC1;
  cursor: pointer;
  margin-left: 0.5rem;
  font-size: 1.2rem !important;
}

.editing .ant-form-item-label > label::after {
  content: none;
}

.editing .ant-radio-group {
  display: inline-flex;
}

.editing .ant-radio-button-wrapper {
  min-width: 135px;
  text-align: center;
}

.editing [type="radio"] + span:before, [type="radio"] + span:after {
  content: none;
}

.Form .ant-alert {
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  width: 70%;
}

.ant-row {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.editingSearchContent {
  display: flex;
  flex-direction: column;
}

.Editing-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #137CC1;
  height: 4rem;
  padding-inline: 1rem;
}

.Max-logo {
  width: 10rem;
}

.Max-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #0F1D3C;
  height: 4rem;
  padding-inline: 1rem;
}

.Max-header-subContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Max-price-detail {
  color: #EBA039;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  margin-block: 1rem!important;
  font-family: 'Sweet Sans Pro';
}

.Max-Method-detail {
  color: #0F1D3C;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-top: 2rem!important;
  font-weight: bold;
  text-align: center;
  font-family: 'Sweet Sans Pro';

}

.Max-header-container-search {
  display: flex;
  flex-direction: row;

  background-color: #0F1D3C;
  height: 3.1rem;
  margin-top: -1px;
  padding-inline: 2rem;
}

.Editing-header-subContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 6rem;
  justify-content: space-between;
}


.AMPM-pay-header {
  padding-left: 1rem;
  padding-top: 0.2rem;
}

.AMPM-pay-header-subtitle {
  color: #137CC1;
  font-family: 'Sweet Sans Pro';
  font-size: 1.2rem;

}

.AMPM-pay-paginator {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.8rem;
}

.AMPM-pay-header-amount {
  color: #137CC1;
  font-family: 'Sweet Sans Pro';
  font-size: 3rem;
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin-bottom: 2rem!important;
}

.AMPM-pay-paginator-left {
  color: #137CC1;
  font-size: 1.1rem;

}

.AMPM-pay-paginator-text {
  color: #137CC1;
  font-family: 'Sweet Sans Pro';
  font-size: 1.2rem;

}


@media screen and (min-width: 769px) {
  .AMPM-pay-header-subtitle {
    text-align: center;
    width: 100%;
  }

  body {
    overflow-y: scroll;
  }

  body::-webkit-scrollbar {
    width: 8px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: #137CC1;
    border-radius: 0 0 4px 4px;
  }

  body::-webkit-scrollbar-thumb:hover {
    background-color: #333;
  }

}

.editingOrderCashier {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 10px;
  height: 2rem;


}

.Editing-header-Search {
  color: #b9b9b9;
  font-size: 1.8rem;
  margin-right: 1rem;
}

.Editing-logo {
  width: auto;
  height: 3rem;
}

.new-header-search-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-inline: 3rem;
  position: relative;
}

.Cashier-breadcrumb-span {
  cursor: pointer;
}

.print-view {
  height: 100% !important;
  overflow: auto !important;
}

html, body {
  height: auto !important;
  height: initial !important;
  overflow: visible !important;
  overflow: initial !important;
}

.AMPMfoot{
  width: 2rem;
  height: 2rem;
  display: flex;
}
.new-header-cart-icon {
  margin-right: 0.5rem;
  font-size: 2.1rem !important;
}
.ant-radio-button-inner{
  display: none!important;
}
.new-header-cart-icon__full {
  color: #70b96c;
  text-overflow: ellipsis;
  -webkit-box-orient: horizontal;
}

.editing-paymentoptions-container{
  display: flex;
  height: 80%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.editing-paymentoptions-text{
  color:#137CC1;
  font-family: 'Sweet Sans Pro';
  margin-bottom: 2rem!important;
  font-size: 1.3rem;
}
.ant-radio-button ant-radio-button-checked{
  color: white !important;
  background: #137CC1 !important;
  border-color: #137CC1 !important;
}

.new-header-cart-modal {
  width: 58vw;
  margin-inline: 3vw;
  max-height: 90vh;
  height: -webkit-max-content;
  height: max-content;
  overflow-y: scroll;
  position: absolute;
  top:5%;
  left: 300px;
  z-index: 999;
  background-color: #FFFFFF;
  border-radius: 17px;
}

.Cart-switch {
  margin-left: 0.5rem;
  width: 0.5rem;
}

.billingInformation-title{
  color:#137CC1;
  font-family: 'Sweet Sans Pro';
}

.ant-switch-checked {
  background: #00704A;
}

.ant-switch:focus{
  outline: none;
  box-shadow: none;
  border-color: transparent;
}
.New-Cart-switch-billing-information {
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0.7rem;
  align-items: center;
}

.new-header-cart-modal::-webkit-scrollbar {
  display: none;
}

.new-cart-title{
  margin:0 0.5rem 0 1rem;
  font-family: 'Sweet Sans Pro';
  color: #137CC1;
  font-size: 1.3rem;
}
.new-header-cart-modal-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  padding-inline: 0.5rem;
}

.New-Cart-Modal-Opacity{
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.58);
}

.new-header-cart-modal-clean {
  left: 0;
  top: 0;
  width: 100%;
  height: 35px;
  display: flex;
  z-index: 10000;
  position: absolute;
  align-items: center;
  background-color: #fafafa;
  justify-content: space-between;
}

.new-header-cart-modal-clean span {
  margin-left: 10px;
}

.new-header-cart-modal-clean u {
  cursor: pointer;
  margin-right: 10px;
}

.new-header-cart-modal-close-btn {
  font-size: 1.2rem;
  margin: 0;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  padding: 0;
  color: #137CC1;
}
.New-cart-products-container{
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.new-header-cart-modal-product {
  width: 90%;
  padding-right: 1rem;
  height: 5rem!important;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.new-header-cart-modal-product-info {
  width: 60%;
  float: left;
  display: flex;
  flex-direction: initial;
}

.new-data-product-cart {
  display: flex;
  min-height: 90%;
  min-width: 8rem!important;
  flex-direction: column;
  justify-content: space-evenly;
  padding-block: 1rem;
}
.New-cart-item-productImg{
  width: 4rem;
  height: 4rem;
  margin: 5px;
  background-size: contain;
}
.New-Cart-Icon-Outlined-Gift {
  z-index: 1;
  color: #FF0000;
  text-align: center;
}

.NewCart-Card-Flag{
  position: relative;
  top: 5px;
  left: 0px;
  width: 25%;
  height: 25px;
  font-size: 0.7rem;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #F12535;
  font-family: 'Sweet Sans Pro';
  border-radius: 8px;
}

.NewCart-TagsFilled{
  font-size: 1rem;
  color: #F12535;
}

.new-cart-item-name {
  font-size: 0.8rem;
  max-height: 2rem;
  line-height: 13px;
  text-overflow: ellipsis;
  margin-top: 5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  font-family: 'Sweet Sans Pro';
  text-overflow: ellipsis;
  color: #137CC1;
  -webkit-box-orient: vertical;
}

.modal-content {
  padding: 0;
  width: 100%;
  max-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-cart-item-measure {
  color: #b5b5b5;
  font-size: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.new-cart-item-name-total {
  float: left;
  display: flex;
  font-size: 14px;
  color: rgba(0,0,0,.85);
  flex-direction: column;
  align-items: flex-start;
}

.Span-total-name {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.85);
}

.Span-total-cant-name {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
}

.loyalty-right-icon{
  color:#137CC1!important;
  margin-right: 1rem!important;
  margin-left: 0.5rem ;
  margin-top: 0.1rem;
  font-size: 1.3rem;
}

.Span-total-loyalty-points {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
}

.new-total-price {
  display: flex;
  justify-content: left;
  flex-direction: column;
}

.new-cart-item-price {
  font-size: 1rem;
  color: #137CC1;
  font-family: 'Sweet Sans Pro';
}

.new-cart-item-price-payment-method {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
}

.new-cart-content-price-total {
  float: right;
  margin-top: 5px;
  display: inline-flex;
  flex-direction: column-reverse;
}

.new-cart-item-price-total {
  float: left;
  line-height: 17px;
  font-family: "Sweet Sans Pro";
  color: rgba(0, 0, 0, 0.85);
}
ant-select-arrow{
  font-size: 0.3rem!important;
  color: #137CC1;
}

.New-cart-quantity-select{
width:50px!important;
;
}
.new-cart-method-T{
  font-family: "Sweet Sans Pro";
  color: #137CC1;
  font-size: 1rem;
  padding-inline: 5px;
  margin-bottom: 0.8rem!important;

}
.new-cart-method-Cont{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-cart-icon-payment-method {
  padding: 5px;
  color: #137CC1!important;
  font-size: 2rem;
}
.new-cart-add-button{
  border-radius: 50%;
  height: 1.3rem;
  width: 1.3rem;
  color: #137CC1;
  background-color: #F8F1E5;
  display: flex;
  font-weight: 700;
  font-size: 1.3rem;
  justify-content: center;

  align-items: center;
  padding-bottom: 0.2rem;
}
.new-cart-quantity{
  color: #137CC1;
  font-size: 1rem;
}
.new-header-cart-modal-product-adding {
  display: flex;
  width: 4rem;
  height: -webkit-min-content;
  height: min-content;
  align-items: center;
  justify-content: space-between;

}

.new-header-cart-modal-product-adding .new-item-adding-btn {
  border: none !important;
  background-color: #fafafa !important;
}

.new-header-cart-modal-product-adding .btn-floating {
  box-shadow: none;
  background-color: transparent !important;
}

.new-header-cart-modal-product-adding .new-item-adding .btn-floating[disabled] {
  background-color: #fafafa !important;
}

.new-header-cart-modal-product-adding .new-item-adding .new-item-adding-btn:hover, .new-item-adding-btn:focus {
  border: none !important;
  background-color: #fafafa !important;
}

.new-header-cart-modal-product-adding .btn-floating[disabled] i {
  color: #9F9F9F !important;
}

.new-header-cart-payBTN {
  width: 90%;
  background-color: #137CC1;
}

.new-cart-order-confirm {
  display: block;
}

.editing-cart-order-confirm-btn{
  text-align: center;
  font-family: 'Sweet Sans Pro';
  align-self: center;
  alignItems: center;
  font-size: 1rem!important;
  text-transform: capitalize!important;
  font-family: 'Sweet Sans Pro';
  background-color: #137CC1!important;
  padding-bottom: 2px;
  margin: 2rem 0px 0px 0px;
  width: 12rem;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 47px;
  cursor: pointer;
}

.new-header-cart-payBTN:hover, .new-cart-order-confirm-btn:hover {
  color: white;
  background-color: #a1cc9c!important;
}

.new-header-cart-modal-product-footer {
  z-index: 1;
  padding: 1%;
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  flex-direction: column;
  background-color: #fafafa;
  justify-content: space-evenly;
}

.new-divider-cart {
  margin: 0 0;
}

.new-cart-total {
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding-block: 1rem;
}

.new-cart-payment-methods {
  width: 90%;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.new-radio-payment-methods {
  width: 100%;
  margin-bottom: 40px;
}

.ant-drawer-close {
  border: none !important;
  background-color: transparent !important;
}

.ant-drawer-close:hover .ant-drawer-close:focus {
  border: none !important;
  background-color: transparent !important;
}

.ant-radio-group .ant-radio-group-outline {
  width: 100%;
  display: inline-grid;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #137CC1!important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #137CC1;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #137CC1 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #137CC1;
  background: #fff;
  border-color: #137CC1;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #137CC1;
  border-color: #137CC1;
}

.ant-radio-button-wrapper:hover {
  color: #137CC1;
}

.ant-drawer-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

[type="radio"]:checked + span:after, [type="radio"].with-gap:checked + span:after {
  background-color: transparent;
}

[type="radio"]:not(:checked)+span:before, [type="radio"]:not(:checked)+span:after {
  border: transparent;
}

[type="radio"]:checked + span:after, [type="radio"].with-gap:checked + span:before, [type="radio"].with-gap:checked + span:after {
  border: transparent;
}

.ant-btn-primary {
  color: white !important;
  background: #70b96c !important;
  border-color: #137CC1 !important;
}

.ant-btn-primary:hover {
  color: white !important;
  background: #a1cc9c !important;
  border-color: #a1cc9c !important;
}

.ant-btn {
  border: none;
  background-color: transparent;
}

.adm-button:focus {
  color: #ffffff;
  background-color: #878d87 !important;
}

.new-swipe-action-cart {
  background: transparent !important;
}
.Cart-loyaltyPoints-Title{
  color:#137CC1;
  font-family: 'Sweet Sans Pro';
}

.ant-drawer-content-wrapper {
  height: 100%;
}

.Cart-loyaltyPoints {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1.7rem;
}

.Cart-loyaltyPoints-input {
  display: flex;
  align-items: center;
}

.New-cart-Loyalty-Input-C{
  display: flex;

  height: 2rem;
  align-items: center;
  color:#000;
}
.Cart-input-addPoints {
  height: 2rem!important;
  margin-top: 0.5rem!important;
  border: 0!important;
  color: black!important;
  max-width: 6rem!important;
  margin-left: 10px!important;

}

@media only screen and (max-width: 766px) {
  .adm-list-item-content-arrow {
    padding-left: 0 !important;
  }

  .new-header-cart-modal {
    width: 94vw;
    margin-inline: 3vw;
    max-height: 90vh;
    height: -webkit-max-content;
    height: max-content;
    overflow-y: scroll;
    position: absolute;
    top:5%;
    left: 0;
    z-index: 999;
    background-color: #FFFFFF;
    border-radius: 17px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px)
and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait) {
  .new-cart-order-confirm-message {
    font-size: 13px;
  }

  .new-cart-order-confirm-btn {
    font-size: 7px;
  }
}
.ant-select {
    width: 100%;
    margin-bottom: 15px;
}
.billingInformation-title{
    font-size: 16px;
    padding-bottom: 10px;
}
.Form-input{
    color:#000
}
.Form{
    margin-left: 0!important;
}

.Form-billing {
    margin: 0 1rem !important;
    background-color: white;
    padding: 0 1rem;
}


@media screen and (min-width: 768px){
    .Form-billing {
        margin: 0 5rem !important;
        padding: 0 1rem;
    }
}
.spinner {
  animation: spin 1s infinite linear;
  border: solid 2vmin transparent;
  border-radius: 50%;
  border-right-color: #ec692e;
  border-top-color: #ec692e;
  box-sizing: border-box;
  height: 20vmin;
  left: calc(50% - 10vmin);
  position: fixed;
  top: calc(50% - 10vmin);
  width: 20vmin;
  z-index: 1;
}
.spinner:before {
   animation:spin 2s infinite linear;
   border:solid 2vmin transparent;
   border-radius:50%;
   border-right-color: #b65b32;
   border-top-color: #b65b32;
   box-sizing:border-box;
   content:"";
   height:16vmin;
   left:0;
   position:absolute;
   top:0;
   width:16vmin;
}
.spinner:after {
   animation:spin 3s infinite linear;
   border:solid 2vmin transparent;
   border-radius:50%;
   border-right-color: #f19971;
   border-top-color: #f19971;
   box-sizing:border-box;
   content:"";
   height:12vmin;
   left:2vmin;
   position:absolute;
   top:2vmin;
   width:12vmin;
 }

@keyframes spin {
  100% {
    transform:rotate(360deg);
  }
}

.ampmBackground{
display:  flex;
  background-color: #137CC1;
  height: 100vh;
  width: 100vw;
}

.MaxBackground{
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #0F1D3C;
}

.loader-fb {
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    position: absolute !important;
}
.loader-fb, .loader-fb:before, .loader-fb:after {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 50px;
    background-color: rgb(135, 141, 135);
    border: 1px solid rgb(135, 141, 135);
    z-index: 100000;
    content: ' ';
    margin-left: -5px;
    margin-right: -7px;
}
.loader-fb:before {
    top: -11px;
    left: -100%;
    animation: loading-fb .8s cubic-bezier(.4,.5,.6,1) infinite;
}
.loader-fb {
    animation: loading-fb-main .8s cubic-bezier(.4,.5,.6,1) .2s infinite;
}
.loader-fb:after {
    top: -11px;
    right: -100%;
    margin-top: 50%;
    animation: loading-fb .8s cubic-bezier(.4,.5,.6,1) .4s infinite;
}

@keyframes loading-fb {
    from {
        transform: scaleY(1.4);
        background-color: rgba(5, 8, 9, 0.55);
        border: 1px solid rgba(5, 8, 9, 0.55);
    }
}

@keyframes loading-fb-main {
    from {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: -10px;
        background-color: rgba(5, 8, 9, 0.55);
        border: 1px solid rgba(5, 8, 9, 0.55);
    }
}
.New-product-main-container{
height: -webkit-max-content;
height: max-content;
  display: flex;
  flex-direction: column;

}
.Cashier-item-list-editing {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1%;
  padding-bottom: 2%;
  flex-direction: row;
  justify-content: space-evenly;
}

.New-Product-item-Img {
  height: 12rem;
  margin: 0px;
  background-color: white;
  margin:0;
  border-radius: 17px;
}

.New-item-img__full {
  color: #fafafa;
  background-color: #137CC1;
  right: 12%;
  border-radius: 0px 17px 0px 0px ;
}

.Max-item-img__full {
  color: #fafafa;
  background-color: #0F1D3C;
  right: 1.7rem;
  border-radius: 0px 17px 0px 0px ;
}


.Cashier-item-img-quantity {
  color: #f7f7f7;
}

.New-product-item-name {
  font-family: 'Sweet Sans Pro';
  color: #000;
  text-align: center;
  font-size: 1rem;
  max-height: 2.5rem;
  min-height: 2.1rem;
  max-width: 80%;
  line-height: 1.2rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  margin: 0px;
  -webkit-box-orient: vertical;
  align-self: center;
  margin-bottom: -0.8rem;
}

.New-product-item-price {
  text-align: center;
  font-family: 'Sweet Sans Pro';
  font-size: 1.2rem;
  color: #000;
  margin-top: -6px;
  font-weight: 600;

}
.Max-product-item-price {
  text-align: center;
  font-family: 'Sweet Sans Pro';
  font-size: 1.2rem;
  color: #0F1D3C;
  margin-top: -6px;
  font-weight: 600;

}

.New-product-item-price-withOutPromo {
  text-align: center;
  font-family: 'Sweet Sans Pro';
  font-size: 1.2rem;
  font-family: 'Sweet Sans Pro';
  color: #000;
  margin: 0px;
  font-weight: 600;
}

.Max-product-item-price-withOutPromo {
   text-align: center;
   font-family: 'Sweet Sans Pro';
   font-size: 1.2rem;
   font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
   margin: 0px;
   font-weight: 600;
 }


.New-product-Purchase {
  text-align: center;
  font-family: 'Sweet Sans Pro';
  align-self: center;
  font-size: 1.1rem;
  alignItems: center;
  font-family: 'Sweet Sans Pro';
  background-color: #137CC1;
  margin: 0.5rem 0px 0px 0px;
  width: 70%;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 47px;
  cursor: pointer;
}



.New-product-Purchase-unn {
  text-align: center;
  align-self: center;
  font-size: 1.1rem;
  alignItems: center;
  font-family: 'Sweet Sans Pro';
  background-color: silver;
  padding-bottom: 2px;
  margin: 0.5rem 0px 0px 0px;
  width: 100%;
  color: #fff;
  height: 2rem;
  border-radius: 47px;
}

.Cashier-item-controls {
  display: flex;
  justify-content: center;
}

.New-item-price-Promo {
  margin: 0;
  text-align: center;
  font-family: 'Sweet Sans Pro';
  color: rgba(107, 104, 112, 0.51);
  font-size: 13px;
  text-decoration: line-through;
}

.New-item-price-Container {
  display: flex;
  flex-direction: column;
  height: 3rem !important;
  margin-top: 10px;
}

.ampm-Icon-Outlined-Gift-card {
  width: 16% !important;
  color: #F12535;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1% !important;
  left: 12.5% !important;

  border-radius: 5px;
  aspect-ratio: 1;

}
.New-item-controls-content{
  display: flex;
  height: 91px;
  padding-inline: 8%;
  flex-direction: column;
}
.New-Icon-Promotion-Text{
  color: #F12535;
  display: block;
  font-size: 1rem;
  margin-top: -0.4rem;
  margin-left: 0.5rem;
  font-family: 'Sweet Sans Pro';
}

.New-product-item-img{
  height: -webkit-max-content;
  height: max-content;
  min-height: 12rem;
  width: 100% !important;
  display: flex;
  color: silver;
  cursor: pointer;
  text-align: center;
  font-family: 'Sweet Sans Pro';
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.New-product-item {
  height: 23rem!important;
  margin-bottom: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 270px;
  margin-inline: 0.3rem;
}
@media screen and (max-width: 750px) {
  .New-product-item {
    width: 178px;
   }
  .New-item-img__full {
    right: 0.3rem;
  }
  .New-item-price-Promo {
    margin-top: 0.6rem;
    margin-bottom: -0.3rem;
  }
}

@media screen and (min-width: 750px) and (max-width: 1050px) {
  .New-product-item {
    width: 220px;
   }
  .New-product-Purchase{
    padding-bottom: 2px;
  }
  .New-item-img__full {
    right: 1.8rem;
  }
  .Max-item-img__full {
    right: 1.8rem;
  }
}

@media screen and (min-width: 1050px) and (max-width: 1400px){
  .New-product-item {
    width:230px;
    height: 25rem!important;
    }
  .New-item-img__full {
    right: 1.7rem;
  }
  .Max-item-img__full {
    right: 1.7rem;
  }
  .New-product-Purchase{
       padding-bottom: 2px;
     }

}

@media screen and (min-width: 1400px) {
  .New-product-item {
    width: 16rem;
    height: 25rem!important;
   }
  .New-product-Purchase{
    padding-bottom: 2px;
  }
}
.PurchaseProduct {
    margin: 0 0 1.2rem 0!important;
    padding: 0!important;
    display: flex;
    height: 28px!important;
    width: 16rem!important;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 30px;
    align-items: center;
    margin-bottom: 1.2rem;
    justify-content: center;
    background-color: #70b96c;
    background-clip: content-box;
}

.PurchaseProduct i {
    font-size: 2rem !important;
}

.ant-modal-footer {
    display: flex !important;
    justify-content: center !important;
}

.PurchaseProduct__unavailable {
    margin: 0 0 1.2rem 0!important;
    padding: 0!important;
    display: flex;
    height: 28px!important;
    width: 16rem!important;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 30px;
    align-items: center;
    margin-bottom: 1.2rem;
    justify-content: center;
    background-color: silver;
    background-clip: content-box;
}

.Cashier-item-adding {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Cashier-item-adding .Cashier-item-adding-btn {
  color: #4a82ff;
  border: none !important;
  background-color: #ffffff;
}

.Cashier-item-adding .btn-floating {
  box-shadow: none;
  background-color: #ffffff;
  margin-inline: 0.5rem;
}

.Cashier-item-adding .btn-floating[disabled] {
  border: none !important;
  background-color: #ffffff !important;
  margin-inline: 0.5rem;
}

.Cashier-item-adding .Cashier-item-adding-btn:hover, .Cashier-item-adding-btn:focus {
  border: none !important;
  background-color: #ffffff !important;
}

.Cashier-item-adding .btn-floating i {
  color: #4a82ff;
  font-size: 2rem !important;
}

.Cashier-item-adding .btn-floating[disabled] i {
  color: #9F9F9F !important;
}

.Cashier-item-adding .btn-floating[disabled] i  {
  color: #ffffff;
}


@media only screen and (max-width: 600px) {
  .Cashier-item-adding .btn-floating i {
    color: #4a82ff;
    font-size: 1rem !important;
  }
}


.New-Modal-Product-Description{
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0px;
}

.Max-Modal-Product-Description{
  font-weight: 500;
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
  text-transform: capitalize!important;
  font-size: 1.2rem;
  margin-bottom: 0px;
}
.Max-Modal-Product-Description2{
  font-weight: 400;
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
  text-transform: capitalize!important;
  font-size: 1rem;
  margin-bottom: 0px;
}

.Max-Modal-Product-Description3{
  font-weight: 700;
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
  font-size: 1rem;
  margin-bottom: 0px;
}
.New-modal-detail-Price{
  font-family: 'Sweet Sans Pro';
  color: #137CC1;
  font-size: 2rem;
}

.Max-modal-detail-Price{
  font-family: 'Sweet Sans Pro';
  color: #EBA039;
  font-size: 1.7rem;
  font-weight: 600;
  margin-bottom: 2rem!important;
}
.Max-modal-detail-Name{
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
  font-size: 1rem;
  font-weight: 600;
}
.ant-modal-wrap::-webkit-scrollbar {
  display: none;
}
.New-Icon-Promotion-Text{
  color: #F12535;
  display: block;
  font-size: 1rem;
  margin-top: -0.4rem;
  margin-left: 0.5rem;
  font-family: 'Sweet Sans Pro';
}

.New-modal-Icon-Outlined-Gift{
  width: 20%;
  color: #F12535;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 2rem !important;
  left: 2rem !important;
}

.New-modal-detail-container-img{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
}


.New-modal-detail-container-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.New-modal-detail-item-img {
  height: 12em;
  display: flex;
  color: silver;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.New-modal-detail-item-img i {
  font-size: 8rem;
}

.New-modal-detail-details{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.New-modal-detail-circle{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 0.5rem;
  margin-bottom: 0.7rem;
}

.New-modal-detail-promoPrice{
  color: #9c9d9d;
  margin-bottom: 0.5rem;
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

.New-modal-detail-rate{
  margin-bottom: 0.5rem;
}

.ant-rate-star-first, .ant-rate-star-second{
  color: #d8d8d8;
}

.New-modal-favorite > span > svg{
  width: 25px;
  height: 25px;
  color: #d8d8d8;
  cursor: pointer;
}

.New-modal-favorite-selected span > svg{
  width: 25px;
  height: 25px;
  color: #fadb14;
  cursor: pointer;
}

.ant-list-split .ant-list-header{
  border-bottom: 1px solid #d8d8d8;
}
.ant-modal-footer{
  padding-inline: 3rem;
  padding-bottom: 2rem;
}
.New-modal-detail-container-rateAndPrice{
  display: flex;
  justify-content: space-between;


}
.Max-modal-detail-container-rateAndPrice{
  display: flex;
  justify-content: space-between;
  margin-bottom: -1rem;

}

.detailContainerMax{
  margin-bottom: 1rem;
}
.New-product-Purchase{
  justify-content: center;
  width: 100%;
  height: 2rem;
  background-color:#137CC1;
}


.New-modal-detail-container-combos{
  display: flex;
  padding-top: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.New-modal-detail-promoPrice{
  font-family: 'Sweet Sans Pro';
  color: #137CC1;
  font-size: 1.3rem;
  margin-top: 0.3rem;
  margin-bottom: -0.4rem;
  line-height: 1rem;
  color: rgba(107, 104, 112, 0.51);
  text-decoration: line-through;
}

.ant-modal-body{
  padding:2rem;
  padding-bottom: 0;
}

.ant-image-preview-img{
  background-color: #FFFFFF;
}

.New-modal-detail-container-combos-text-container{
  display: flex;
  flex-direction: column;
}
.New-modal-detail-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: -webkit-min-content;
  height: min-content;
}
.New-container-modal-main{

}

.New-modal-detail-container-combos-title{
  background-color: white;
  font-size: 18px;
  font-weight: 600;
  margin: 0!important;
}

.New-modal-detail-container-combos-subtitle{
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  color: #888888;
  margin: 0!important;
}

.New-modal-detail-container-combos-down{
  color: #BFBFBF;
  font-size: 26px;
}

.New-modal-detail-combos-item{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 5px;
}

.New-modal-detail-combos-item-container{
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.New-modal-detail-combos-item-title{
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin: 0!important;
}

.New-modal-detail-combos-circle{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #BFBFBF;

}
.PurchaseProduct {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-clip: content-box;
  background-color: #70b96c;
  color: white;
  border-radius: 38px;
  padding: 5px 10px;
  width: 7rem;
}

.PurchaseProduct i {
  font-size: 2rem !important;
}

.Max-item-withOutStock {
  border-radius: 20px!important;
  background-color: grey;
  width: 12rem;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Sweet Sans Pro';
  padding-bottom: 2px;
  }
.Max-item-withStock {
  border-radius: 20px!important;
  background-color: #EBA039;
  width: 12rem;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Sweet Sans Pro';
  padding-bottom: 2px;
}

.PurchaseProduct__unavailable {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-clip: content-box;
  background-color: silver;
  color: white;
  border-radius: 20px;
  padding: 5px 10px;
  margin-left: 10%;
  margin-right: 10%;
}

.Editing-item-adding {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  border-radius: 1rem !important;
}

.Editing-item-adding .Cashier-item-adding-btn {
  color: #137CC1;
  border: none !important;
  background-color: #ffffff;
}

.Editing-item-adding-btn{

  width: 2.3rem!important;
  height:2.3rem!important;
  border-radius: 50% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white!important;

}
.Cashier-item-remove-btn{
  margin-top: 0.6rem;
  size: 0.8rem!important;
}
.Editing-item-adding .btn-floating {
  box-shadow: none;
  background-color: #ffffff;
  height: 3rem;
}

.Editing-item-adding .btn-floating[disabled] {
  border: none !important;
  background-color: #ffffff !important;
}

.Editing-item-adding .Cashier-item-adding-btn:hover, .Cashier-item-adding-btn:focus {
  border: none !important;
  background-color: #ffffff !important;
}

.Editing-item-adding .btn-floating i {
  color: #137CC1;
  font-size: 2rem !important;
}

.Editing-item-adding .btn-floating[disabled] i {
  color: #9F9F9F !important;
}

.Editing-item-adding .btn-floating[disabled] i  {
  color: #ffffff;
}

.PurchaseProduct {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-clip: content-box;
  background-color: #70b96c;
  color: white;
  border-radius: 38px;
  padding: 5px 10px;
  width: 7rem;
}
.Max-product-Purchase{
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: center;
}
.PurchaseProduct i {
  font-size: 2rem !important;
}

.Max-item-withOutStock {
  border-radius: 20px!important;
  background-color: grey;
  width: 12rem;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Sweet Sans Pro';
  padding-bottom: 2px;
}

.Max-item-withStock {
  border-radius: 20px!important;
  background-color: #EBA039;
  width: 10rem;
  text-align: center;
  color: #fff;
  display: flex;
  font-size: 90%;
  justify-content: center;
  align-items: center;
  font-family: 'Sweet Sans Pro';
  padding-bottom: 2px;
}

.Max-item-withStockBS {
  border-radius: 20px!important;
  background-color: #EBA039;
  width: 8rem;
  margin-top: 1rem;
  text-align: center;
  color: #fff;
  display: flex;
  font-size: 0.8rem;
  justify-content: center;
  align-items: center;
  font-family: 'Sweet Sans Pro';
  padding-bottom: 2px;
}

Max-item-withOutStock-modal{
  border-radius: 20px!important;
  background-color: grey;
  width: 18rem;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Sweet Sans Pro';
  padding-bottom: 2px;
  height: 2rem;
}

.Max-item-withStock-modal {
  border-radius: 20px!important;
  background-color: #EBA039;
  width: 18rem;
  text-align: center;
  color: #fff;
  display: flex;
  font-size: 90%;
  justify-content: center;
  align-items: center;
  font-family: 'Sweet Sans Pro';
  padding-bottom: 2px;
  height: 2rem;
}
.PurchaseProduct__unavailable {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-clip: content-box;
  background-color: silver;
  color: white;
  border-radius: 20px;
  padding: 5px 10px;
  margin-left: 10%;
  margin-right: 10%;
}

.Editing-item-adding {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Editing-item-adding .Max-item-adding-btn {
  color: #0F1D3C;
  border: none !important;
  background-color: #ffffff;
}

.Editing-item-adding   {
  box-shadow: none;
  background-color: #ffffff;
}

.Editing-item-adding .Cashier-item-adding-btn {
  color: #0F1D3C;
  border: none !important;
  background-color: #ffffff;
}

.Editing-item-adding .btn-floating {
  box-shadow: none;
  background-color: #ffffff;
  color: #0F1D3C;
}

.Editing-item-adding .btn-floating[disabled] {
  border: none !important;
  background-color: #ffffff !important;
  color: #0F1D3C;

}


.max-cart-add-but{
  border-radius: 50%;
  box-shadow: none;
  height: 1.5rem;
  width: 1.5rem;
  color: #0F1D3C;
  display: flex;
  background-color:  transparent!important;

  font-weight: 700;
  font-size: 2rem;
  justify-content: center;

  align-items: center;
  padding-bottom: 0.2rem;
}
.Max-cart-quantity{
  color: #000;
  font-size: 1.2rem;
  margin-bottom: 0.4rem;
}


.Max-header-cart-modal-product-addg {
  display: flex;
  align-self: center;
  height: 2rem;
  background-color: #FFFFFF;
  padding-inline: 1rem;
  border-radius: 12px;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  min-width: 6rem;

}
.Editing-item-adding  [disabled] {
  border: none !important;
  background-color: #ffffff !important;
}

.Editing-item-adding .Max-item-adding-btn:hover, .Max-item-adding-btn:focus {
  border: none !important;
  background-color: #ffffff !important;
}

.Editing-item-adding   i {
  color: #0F1D3C;
  font-size: 2rem !important;
}

.Editing-item-adding  [disabled] i {
  color: #9F9F9F !important;
}

.Editing-item-adding  [disabled] i  {
  color: #ffffff;
}
.Max-cart-control-cont2{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 6rem;
  align-items: center;

}
.Max-cart-control-cont{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  min-width: 6rem;
  align-items: center;
  margin-bottom: -10px!important;
}
.Max-cart-control-text2{
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
  font-size: 0.9rem;
  margin-top: -10px;
}
.Max-cart-control-text{
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
  font-size: 1rem;
  margin-top: 12px;
  text-align: center;
  cursor: pointer;
}
.Max-cart-control-text::-webkit-inner-spin-button,
.Max-cart-control-text::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Max-cart-control-text {
  -moz-appearance: textfield;
}

.Max-cart-control-icon{
  font-size: 1.2rem;
  color: #0F1D3C!important;
  background-color: #FFFFFF;
  border-radius: 50%;

  padding: 3px;
}
.Search {
    margin: 0 !important;
    border: none !important;
}

.form-control {
    height: 40px;
    line-height: 40px;
}

.ant-btn:hover {
    background-color: transparent;
}

.ant-btn:focus {
    background-color: transparent;
}

#Cashier-Search-puma {
    color: white;
}

.New-header-content-search{
    height: 2.4rem;
    align-items: center;
    display: flex;
    margin-top: 5px;
}

.custom-search-container {
    display: flex;
    align-items: center;
    width: 12rem!important;
    flex-direction: row;
}

.custom-search-input {
    background: none;

}

.custom-search-clear {
    padding: 0 0.5rem;
}

.header-search-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(51, 51, 51);
}

.New-header-search-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

@media only screen and (min-device-width: 1000px){
    .New-header-content-search{
        position: absolute;
        right: 5rem;
        width: 16rem;
        top: 0.5rem;
    }
    .New-header-search-container{
        padding-right: 3rem;
        justify-content: flex-end;
    }
}

.NewSearchIcon{
  color: #117CC1;
  font-size: 1.2rem;
}

.NewSearchIconMax{
  color: #0F1D3C;
  font-size: 1.2rem;
}

.NewSearchContainer{
  border:1px solid #117CC1;
  width: 100%;
  background-color: #FFF;
  margin-inline:  1rem;
  height: 2.5rem;
  padding-inline: 1rem;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  flex-direction: row;
}
.NewSearchContainerMax{
  border:1px solid #0F1D3C;
  width: 100%;
  background-color: #FFF;
  margin-inline:  1rem;
  height: 2.2rem;
  padding-inline: 1rem;
  display: flex;
  align-items: center;
  border-radius: 15.5px;
  flex-direction: row;
}
.NewSearchInput{
  font-family: 'Sweet Sans Pro';
  font-size: 0.8rem;
  color: #000;
}
.NewSearchContainerDesktop {
  border:1px solid #117CC1;
  width: 18rem;
  background-color: #FFF;

  height: 2.5rem;
  padding-inline: 1rem;
  display: flex;
  align-items: center;
  align-self: flex-end;
  border-radius: 1rem;
}

.NewSearchContainerDesktopMax{
  border:1px solid #0F1D3C;
  width: 18rem;
  background-color: #FFF;

  height: 2.5rem;
  padding-inline: 1rem;
  display: flex;
  align-items: center;
  align-self: center;
  margin-right: 1rem;
  border-radius: 1rem;
}
@media screen and (max-width: 800px) {
.category-img {
  width: 100%!important;
  display: flex;
  height: 100px!important;
  text-align: center;
  aspect-ratio: 3/2!important;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}

.category-name {
  min-width: 100% !important;
  display: flex;
  font-size: smaller;
  text-align: center;
  font-family: 'Sweet Sans Pro';
  align-items: center;
}

.category-item-name{
  overflow: hidden;
  font-size: 1rem;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  margin-bottom: 0.5rem;
  min-width: 100% !important;
  -webkit-box-orient: vertical;
}

  .max-category-item-name{
    overflow: hidden;
    font-weight: 700;
    text-align: center;
    font-family: 'Sweet Sans Pro';
    color: #000;
    font-size: 1rem;
    text-align: center;
    display: -webkit-box;
    text-overflow: ellipsis;
  }


.category-item {
  width: 45%;
  border: 0px;
  cursor:pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  flex-direction: column;


}
.Cashier-item {
  width: 45%;
  float: left;
  height: 100%;
  display: flex;
  margin-top: 10px;
  margin-left: 3.3%;
  font-size: 1.5rem;
  border-radius: 3px;
  align-items: center;
  flex-direction: column;
  background-color: transparent!important;
  border: 0px!important;
}

.Max-category-item {
  width: 45vw;
}

.category-item-content{
  align-items: center;
  width: 100%;
  height: 10.5rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.max-category-item-content{
  align-items: center;
  min-width: 45vw !important;
  height: 16rem!important;
  display: flex;
  flex-direction: column;
  margin-bottom: -1rem;
}

.category-item-productImg{
  object-fit: contain;
  width: 100%;
  aspect-ratio: 3/2!important;
  border-radius: 28px;
  max-width: 11.4rem;
}
.category-item-productImg-Max{
  width: 100%;
  aspect-ratio: 1!important;
  border-radius: 28px;
  max-width: 11.4rem;
  max-height: 11.4rem;
  margin: 0;
  padding: 0;
  object-fit: cover;
}
  .category-item-noImg-Max{
    width: 100%;
    aspect-ratio: 1!important;
    border-radius: 28px;
    max-width: 11.4rem;
    max-height: 11.4rem;
    margin: 0;
    height: 100%;
    font-size: 5rem;
    padding: 0;
    object-fit: contain;
    padding-top: 3rem;
    color: rgba(0, 0, 0, 0.2);
  }

.category-item-productIcon {
  width: 100%;
  height: 90%;
  color: silver;
  cursor: pointer;
}
.category-list-editing-ampm{
  display: flex;
  flex-direction: column;
}
}
@media screen and (min-width: 800px) {
  .category-item-productImg-Max{
    width: 90%;
    height: 11rem;
    border-radius: 28px;
    margin: 0;
    padding: 0;
    object-fit: cover;
  }

  .max-category-item-content{
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: -1rem;
  }
  .Max-category-img {


    display: flex;
    background-size: contain;
    width: 8vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor:pointer;
  }
  .Max-category-item-productImg{
    object-fit: contain;
    width: 100%!important;
    aspect-ratio: 3/2!important;
    border-radius: 28px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    cursor:pointer;
  }
  .Max-category-item {
    border: 0px;
    cursor:pointer;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 2rem;
  }
  .Max-category-item-productImg{
    object-fit: contain;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    aspect-ratio: 3/2!important;
    border-radius: 28px;
  }
  .Max-category-name {
    width: 95%;
    max-width: 18vw;
    height: 40px;
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .max-category-item-name{
    overflow: hidden;
    font-weight: 700;
    text-align: center;
    font-family: 'Sweet Sans Pro';
    color: #000;
    font-size: 1rem;
    text-align: center;
    display: -webkit-box;
    text-overflow: ellipsis;
  }

  .category-img {


    display: flex;
    background-size: cover;
    width: 18vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor:pointer;
  }
  .category-item-productImg{
    width: 100%!important;
    aspect-ratio: 3/2!important;
    border-radius: 28px;
    object-fit: contain;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    cursor:pointer;
  }
  .category-item {
    border: 0px;
    background-color: transparent;
    cursor:pointer;
  }
  .category-item-productImg{
    width: 100%;
    object-fit: contain;
    height: -webkit-max-content;
    height: max-content;
    aspect-ratio: 3/2!important;
    border-radius: 28px;
  }
  .category-name {
    width: 95%;
    max-width: 18vw;
    height: 40px;
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .category-item-name {
    font-weight: 500;
    font-family: 'Sweet Sans Pro';
    font-size: 1.2rem;
    overflow: hidden;
    text-align: left;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
  .Cashier-item {
    background-color: transparent!important;
    border: 0px!important;
  }
}


.PromotionScreen-Container{
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.PromotionScreen-button-next{
  background: #F4F0F3;
  border: 1px solid #46354D;
  border-radius: 47px;
  height: 39px;
  width: 122px;
  color: #46354D;
  border-radius: 47px;
  align-self: flex-end;
  position: fixed;
  bottom: -2vh;
  right: 3vh;
  z-index: 997;
}

.PromotionScreen-header{
  display: flex;
  flex-direction: row;
  min-width: 100% !important;
  justify-content: space-between;
  align-items: center;
  padding-inline: 3%;
  margin-top: 3%;
}

.PromotionScreen-Title{
  color: #000;
  font-family: 'Sweet Sans Pro';
  font-size: 25px;
}

.PromotionScreen-seeAll{
  color: #46354D;
  font-size: 13px;
  font-family: 'Sweet Sans Pro';
  cursor: pointer;
}

.New-Icon-Outlined-Gift-card {
  width: 20%;
  color: #F12535;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 2% !important;
  left: 8% !important;
}

.New-Icon-Promotion-Text{
  color: #F12535;
  display: block;
  font-size: 1rem;
  margin-top: -0.4rem;
  margin-left: 0.5rem;
  font-family: 'Sweet Sans Pro';
}


.PromotionScreen-carousel{
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding-bottom: 20px;
  margin-bottom: 30px;
  padding-inline: 2vw;
  width: auto;
}
.PromotionScreen-carousel::-webkit-scrollbar {
  display: none;
}

.PromotionScreen-paginator-cont{

  margin-top: -1.5rem;
  margin-bottom: 2rem;
  width: 12vw;
  min-width: 7rem;
  margin-inline:auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  color: #2c2c2c;
}

.PromotionScreen-icon{
  font-size: 1.2rem;
  color: #137CC1;
}
.PromotionScreen-icon-d{
  font-size: 1.2rem;
  color: #838383;
}

.PromotionScreen-Card-BS{
  min-width: 15rem;
  width: 15rem;
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  padding-bottom: 1rem;
  height: 23rem!important;
  margin-bottom: 3%;
  justify-content: center;
  max-width: 270px;
  margin-inline: 0.3rem;
}

.loader-fb-promotion {
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  position: relative !important;
}
.loader-fb-promotion, .loader-fb-promotion:before, .loader-fb-promotion:after {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 50px;
  background-color: #137CC1;
  border: 1px solid #0f6da6;
  z-index: 100000;
  content: ' ';
  margin-left: -5px;
  margin-right: -7px;
}
.loader-fb-promotion:before {
  top: -11px;
  left: -100%;
  animation: loading-fb .8s cubic-bezier(.4,.5,.6,1) infinite;
}
.loader-fb-promotion {
  animation: loading-fb-main .8s cubic-bezier(.4,.5,.6,1) .2s infinite;
}
.loader-fb-promotion:after {
  top: -11px;
  right: -100%;
  margin-top: 50%;
  animation: loader-fb-promotion .8s cubic-bezier(.4,.5,.6,1) .4s infinite;
}

@keyframes loader-fb-promotion {
  from {
    transform: scaleY(1.4);
    background-color: rgba(5, 8, 9, 0.55);
    border: 1px solid rgba(5, 8, 9, 0.55);
  }
}

@keyframes loader-fb-promotion-main {
  from {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: -10px;
    background-color: rgba(5, 8, 9, 0.55);
    border: 1px solid rgba(5, 8, 9, 0.55);
  }
}

.PromotionSpinner{
  height: 26rem!important;
  width: 100vw;
  display: flex;
  z-index: 1;
}

.PromotionScreen-TagsFilled{
  font-size: 20px;
  color: #F12535;
}

.PromotionScreen-Card{
  min-width: 15rem;
  max-width: 15rem;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  padding: 0;
  padding-bottom: 0.5rem;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.PromotionScreen-purchaseCont{
  padding-inline: 0.5rem;
}
.PromotionScreen-productImg{
  background-size: contain!important;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  background-color: #FFFFFF;
  width: 90%;
  align-self: center;
  height: 192px;
  margin-top: 8px;

}


.PromotionScreen-Card-Flag{
  position: relative;
  top: 15px;
  left: 0px;
  width: 25%;
  height: 25px;

  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #F12535;
  font-family: 'Sweet Sans Pro';
  border-radius: 8px;
}

.PromotionScreen-name{
  font-family: 'Sweet Sans Pro';
  font-size: 15px;
  max-height: 45px;
  overflow: hidden;
  margin: 0;
}
.PromotionScreen-textContainer{
  display: flex;
  flex-direction: column;
  padding-inline: 5%;
  margin: 0;
  justify-content: space-between;
  height: 8rem;

}

.PromotionScreen-priceContainer{
  height: -webkit-max-content;
  height: max-content;
  display: flex;

  flex-direction: column;
  justify-content: space-between;
}



.PromotionScreen-price{
  font-family: 'Sweet Sans Pro';
  font-size: 20px;
  color: grey;
  margin: 0;
  text-decoration: line-through;
}

.PromotionScreen-promoPrice{
  font-family: 'Sweet Sans Pro';
  font-size: 23px;
  color: #46354D;
  margin: 0;
  margin-bottom: 5px;
}

.PromotionScreen-BS-carousel{
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow-x: scroll;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.PromotionScreen-banner-carousel{
  width: 100%;
  height: 30vh;
  background-color: #6b6b6b;
  display: flex;
  flex-direction: row;
}
.PromotionScreen-banner{
  min-width: 30vw;
  height: 58vh;
  background-size: cover;
  background-repeat:no-repeat;
  background-position: center center;
  overflow-x: hidden;
  margin-inline: 2%;
}

.PromotionScreen-welcome{
  background: url("https://cloudfront-us-east-1.images.arcpublishing.com/infobae/GGNZ6XTIUBGJ5J7LYDUUQIEAGA.jpg");
  background-size: cover;
  height: 40vh;
  width: 65%;
  align-self: center;
  background-position: center 60%;
  display: flex;
  text-align: left;
  padding: 2vw;
  margin-inline: 1%;
}

.PromotionScreen-welcome-text{
  font-family: 'Sweet Sans Pro';
  color: #0c0c0c;
  font-size: 30px;
  text-align: left;
}

.PromotionScreen-left{
  position: absolute;
  font-size: 1.2rem;
  font-family: 'Sweet Sans Pro';
  top: 6rem;
  left: 0.5rem;
  color: #137CC1;
}

.PromotionScreen-AllPromo-Container{
  display: flex;
  flex-direction: column;
}

.PromotionScreen-AllPromo-Grid{
  display: flex;
  flex-wrap: wrap;
  margin-top: 1%;
  padding-bottom: 2%;
  flex-direction: row;
  justify-content: space-evenly;
}
.PromotionScreen-BS-Grid{
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  overflow-x: scroll;
  padding-bottom: 2%;
  flex-direction: row;
}
.PromotionScreen-button-next {
  text-align: center;
  z-index: 997;
  align-self: end;
  font-size: 1.1rem;
  alignItems: center;
  font-family: 'Sweet Sans Pro';
  background-color: #FFF;
  padding-bottom: 2px;
  width: 8rem;
  color: #137CC1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-color: #137CC1;
  border-radius: 47px;
  border-width: 2px;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 1025px) {
  .PromotionScreen-Title{
  
    color: #000;
    font-size: 20px;
  }

  .PromotionScreen-price{
    font-size: 15px;
  }

  .PromotionScreen-promoPrice{
    font-size: 18px;
  }

  .PromotionScreen-name{
    font-size: 13px;
  }


  .PromotionScreen-welcome{
    background: url("https://cloudfront-us-east-1.images.arcpublishing.com/infobae/GGNZ6XTIUBGJ5J7LYDUUQIEAGA.jpg");
    background-size: cover;
    height: 45vh;
    padding-inline: 2vw;
    width: 100%;
    background-position: center 82%;
    display: flex;
    text-align: left;
    margin-bottom: 5%;
  }

  .PromotionScreen-welcome-text{
    font-family: 'Sweet Sans Pro';
    color: #0c0c0c;
    font-size: 23px;
    text-align: left;
    width: 70%;
  }

  .PromotionScreen-banner-carousel{
    width: 100%;
    height: 30vh;
    background-color: #6b6b6b;
    display: flex;
    flex-direction: row;
  }

  .PromotionScreen-banner{
    min-width: 100vw;
    height: 35vh;
    background-size: contain;
    background-repeat:no-repeat;
    background-position: center center;
    overflow-x: hidden;
  }



}

header {
  top: 0;
  z-index: 2;
  height: 3rem;
  width: 100%;
  display: flex;
  color: #8dc491;
  position: -webkit-sticky;
  position: sticky;
  min-height: 81px;
  align-items: center;
  background-color: #ffffff;
  justify-content: space-between;
  border-bottom: 1px solid #d0d0d0;
}

.pumaStatusbutton{
  margin: auto;
  border: 1px solid #0f9d58;
  background-color: #f7f7f7;
  color: #0f9d58;
  border-radius: 8px;
  margin-top: 10px;
  cursor: pointer;
  width: 5rem;
}

.pumaStatusbutton:focus{
  background: #FFF!important;
}

.Filter-Circle-Button {
  height: 2rem;
  margin-top: 0.3rem;
  display: flex;
  color: #6b6b6b;
  cursor: pointer;
  width: -webkit-max-content;
  width: max-content;
  margin-inline: 6px;
  border-radius: 20px;
  align-items: center;
  padding-inline: 10px;
  background-color: #ededed;
  min-width: -webkit-max-content !important;
  min-width: max-content !important;
}

.Filter-Circle-Button-Selected {
  height: 2rem;
  border: solid;
  margin-top: 0.3rem;
  display: flex;
  color: #F12535;
  cursor: pointer;
  margin-inline: 6px;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 20px;
  align-items: center;
  padding-inline: 10px;
  justify-content: center;
  background-color: #ededed;
  border-width: 2px !important;
  border-color: #F12535;
  min-width: -webkit-max-content !important;
  min-width: max-content !important;
}

.Filter-Circle-Button-Container {
  display: flex;
  max-width: 95vw;
  overflow-x: auto;
  flex-direction: row;
  padding-bottom: 6px;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  justify-content: space-between;
}

.Filter-Circle-Button-Container::-webkit-scrollbar {
  display: none
}

.Filter-Circle-Button-Container::-webkit-scrollbar-track {
  display: none
}

.Filter-Circle-Button-Container::-webkit-scrollbar-thumb {
  display: none
}


.input-field > input {
  border-bottom: 1px solid #c2dad8 !important;
}

.header__logo {
  height: 70%;
  cursor: pointer;
  margin-left: 1%;
}

.header__login {
  cursor: pointer;
  font-size: 3rem !important;
}

.Cashier-header-main-content {
  position: absolute;
  display: flex;
  height: auto;
}

.Cashier-header-content {
  display: flex;
  min-height: 64px;
  align-items: center;
}

.Cashier-header-content-logo {
  margin: 5px;
  height: 57px;
  cursor: pointer;
}

.Cashier-breadcrumb-home-content {
  top: 75px;
  height: 8%;
  z-index: 2;
  display: flex;
  color: #8dc491;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 3%;
}

.Status-Container {
  margin-inline: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Cashier-breadcrumb-home-content-editing {
  height: 8%;
  z-index: 1;
  width: 100vw !important;
  display: flex;
  color: #8dc491;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f7f7f7;
  padding-left: 3%;
  padding-right: 2%;
  margin-top: 10px;
}

.Breadcrumb-editing-container {
  margin-top: 25px;
  padding-inline: 1%;
  display: flex;
  flex-direction: row;
}

.Cashier-breadcrumb-left-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 1.5rem !important;
}

.ant-breadcrumb {
  width: -webkit-max-content !important;
  width: max-content !important;
  height: auto !important;
  box-shadow: none !important;
  background-color: transparent !important;

}

.Cashier-breadcrumb-home-icon {
  color: #70b96c;
  cursor: pointer;
  margin-left: 0.5rem;
  font-size: 1rem !important;
}

.Cashier-breadcrumb-span {
  color: #6b6b6b;
  font-size: smaller;
}

.Cashier-header-search {
  margin: 0 !important;
  border: none !important;
}

.Cashier-header-cart {
  display: flex;
  color: #dadada;
  cursor: pointer;
}

.Cashier-header-cart__full {
  color: orange;
  font-size: 1rem;
  animation-duration: 0.5s;
  animation-name: cart__full;
}

.Cashier-header-cart__empty {
  color: #dadada;
  font-size: 1rem;
}

@keyframes cart__full {
  0% {
    font-size: 1rem;
  }
  25% {
    font-size: 1.3rem;
  }
  50% {
    font-size: 1.2rem;
  }
  100% {
    font-size: 1rem;
  }
}

.Alert-stock {
  z-index: 3;
  position: fixed;
}

.Cashier {
  float: left;
  width: 100%;
  height: 100%;
  display: block;
}

.Cashier-spinner {
  position: relative;
}

.Cashier-item-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5%;
  padding-top: 125px;
  padding-bottom: 2%;
  flex-direction: row;
}

.Cashier-item-list-editing {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1%;
  padding-top: 125px;
  padding-bottom: 2%;
  flex-direction: row;
}

.Cashier-item-list-empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Cashier-item {
  width: 45%;
  float: left;
  height: 100%;
  display: flex;
  margin-top: 10px;
  margin-left: 3.3%;
  font-size: 1.5rem;
  border-radius: 3px;
  align-items: center;
  flex-direction: column;
  border: 1px solid #dadada;
  background-color: rgba(255, 255, 255, 0.9);
}

.Cashier-category-item {
  height: 43%;
}

.Cashier-item .ant-carousel {
  width: 100%;
}

.Cashier-item-carousel-slide {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.Cashier-item__full {
  border: 1px solid #c6e8c4;
}

.Cashier-item-productImg {
  width: 100%;
  height: 80%;
  object-fit: contain;
}

.Cashier-cart-item-productImg {
  width: 90px;
  margin: 5px;
  height: 100px;
}

.Max-header-cart-modal-Title {

  font-size: 16px;
  font-weight: 790;
  line-height: 21px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  padding-left: 1rem;
  color: #0F1D3C;

}

.Cashier-category-item-productImg {
  height: 90%;
  aspect-ratio: 1 !important;
}

.Cashier-category-item-productIcon {
  width: 100%;
  height: 90%;
  color: silver;
  cursor: pointer;
}

.Cashier-item-img-quantity {
  width: 15%;
  color: #ffffff;
  margin-left: 85%;
  font-size: 1.1rem;
  text-align: center;
  position: absolute;
}

.Cashier-item-img__full {
  color: #fafafa;
  background-color: green;
}

.Cashier-item-name {
  height: 39px;
  margin: 10px;
  overflow: hidden;
  font-size: 0.8rem;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.cashier-item-description {
  margin: 7px;
  height: 75px;
  color: #b5b5b5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  flex-direction: column;
  justify-content: center;
  text-overflow: ellipsis;
}

.Cashier-item-description {
  width: 100%;
  float: left;
  color: #b5b5b5;
  font-size: 0.8rem;
  text-align: center;
  margin: 6px 3px 2px 0;
}

.Cashier-item-controls-content {
  display: flex;
  height: 91px;
  flex-direction: column;
}

.Filter-Text {
  font-size: 0.9rem;
  margin-top: 0.9rem;
  font-family: 'Sweet Sans Pro';
}

.Cashier-item-quantity {
  display: flex;
  font-size: 1rem;
  color: #0F1D3C;
  align-items: center;
  justify-content: center;
}

.Cashier-item-list .Cashier-item-quantity {
  min-width: 60px;
  font-size: 0.9rem;
}

.Cashier-item-price {
  margin: 0;
  text-align: center;
}

.Cashier-item-price-Promo {
  margin: 0;
  text-align: center;
  color: rgba(107, 104, 112, 0.51);
  font-size: 13px;
  text-decoration: line-through;
}

.Cashier-item-price-withOutPromo {
  margin: 0;
  padding-top: 4px;
  text-align: center;
}

.Cashier-item-withOutStock {
  border-radius: 20px;
}

.Cashier-item-variable-price {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Cashier-item-variable-price input {
  width: 5rem !important;
  height: 2rem !important;
}

.Cashier-item-variable-price i {
  font-size: 1.5rem !important;
}

.Cashier-item-variable-price input {
  margin-left: 5px !important;
}

.Cashier-category-img {
  width: 100%;
  display: flex;
  height: 120px;
  margin-top: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Cashier-category-name {
  width: 100%;
  height: 70px;
  display: flex;
  font-size: smaller;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Cashier-item-img {
  height: 12em;
  width: 100% !important;
  display: flex;
  color: silver;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Cashier-item-draw {
  width: 100%;
  position: relative;
}

.Cashier-item-draw-details {
  display: flex;
  margin-bottom: 2px;
  flex-direction: row;
  justify-content: center;
}

.Cashier-item-draw-open {
  color: #bebebe;
  font-size: 1.5rem;
}

.Cashier-item-draw .ant-drawer-content-wrapper {
  position: absolute;
  width: 100% !important;
  height: 135px !important;
}

.Cashier-item-draw-details-content {
  width: 100%;
  height: 73px;
  display: contents;
  flex-direction: column;
  justify-content: center;
  min-width: -webkit-max-content;
  min-width: max-content;
}

.Cashier-props-detail {
  margin: 5px;
  font-size: 0.7rem;
}

.Cashier-item-draw-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.Cashier-item-draw-detail {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Cashier-item-cart-draw-details {
  width: 100%;
  display: flex;
  color: #bfbfbf;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: flex-start;
}

.PayOrder-paymentMethod {
  color: #979898;
  font-size: 1.5rem;
  margin-bottom: 0.5em;
}

.Form .Button-Pay-Card {
  clear: both;
  margin-right: 0;
  margin-top: 10px;
}

.PayOrder-amount {
  font-size: 2rem;
  color: #70b96c;
}

.change-price {
  color: #979898;
  text-decoration: line-through;
}

.PayOrder-price-detail {
  display: block;
}

.PayOrder-loyalty-points {
  display: flex;
  margin-top: -20px;
  margin-bottom: 10px;
  justify-content: center;
}

.ant-checkbox {
  margin-right: 0.3rem;
}

[type="checkbox"] + span:not(.lever):before, [type="checkbox"]:not(.filled-in) + span:not(.lever):after {
  border: none;
}

[type="checkbox"] + span:not(.lever) {
  padding-left: 24px;
}

[type="checkbox"]:checked + span:not(.lever):before {
  border-right: 2px solid white;
  border-bottom: 2px solid white;
}

.ready {
  display: flex;
  color: #0f9d58;
  align-items: center;
  justify-content: center;
}

.fail {
  color: #d27c94;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pending {
  color: #d2ba7c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Cashier-status-text {
  font-size: 1.6rem;
  width: 90%;
  margin-left: 5%;
}

.Cashier-status-text-Max{
  font-family: 'Sweet Sans Pro';
  color: #000;
  font-size: 1rem;
}

.statusMaxContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 1rem;
}

.dividerMaxFactura{
  background-color: #FAA21D;
  width: 100%;
  height: 1px;
}

.Cashier-status-text-MaxTitle {
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
  text-transform: uppercase;
  font-size: 20px;
  padding-inline: 1.2rem;
  font-weight: bolder;
}

.maxThTF{
 max-height: 2rem!important;
  padding: 0;
  border: 0;
  margin: 0;
}
.logoBelca{
width: 200px;
align-self: center;
  margin-block: 2rem;
}
.maxThText{
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;

}

.MaxDivider{
  width: 90vw;
  height: 1px;
  align-self: center;
  background-color: #D9D9D9;
  margin-block: 1.5rem;
}

.maxThTextV{
  font-family: 'Sweet Sans Pro';
  color: #565656;
  text-transform: lowercase;
  font-size: 0.7rem;
  padding: 0.2rem;
  margin: 0;
}
.maxThTextIva{
  font-family: 'Sweet Sans Pro';
  color: #565656;
  font-size: 0.7rem;
  padding: 0.2rem;
  margin: 0;
}
.Cashier-status-price-detail {
  display: block;
  font-size: 1.4rem;
}
.MaxLinks{
  font-family: 'Sweet Sans Pro';
  color: #EBA039!important;
  font-weight: bold;
  margin-block: 0.2rem!important;
}
.Button-confirm-div {
  width: 100%;
}

.Button-confirm-miscellaneous {
  margin-bottom: 15px;
  color: #FFFFFF !important;
}
.PDFDownCont{
  background-color: #FFF;
  height: 100vh;
  width: 100vw;
  padding-inline: 5vw;
}

.PDFloadingCont{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.PDFloadingText{
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
  font-size: 1rem;
  text-align: left;
  padding-inline: 5vw;
  line-height: 1.2rem;
}

.PDFloadingTitle{
  font-family: 'Sweet Sans Pro';
  color: white;
  font-size: 1.1rem;
  height: 1.8rem;
  display: flex;
  border-radius: 1.2rem;
  background-color: #0F1D3C;
  text-align: center;
  align-items: center;
  justify-content: center;
  line-height: 1.2rem;
  margin-top: 3rem;
}

.Button-confirm-miscellaneous-Max:disabled{
  margin-bottom: 15px;
  font-family: 'Sweet Sans Pro';
  color: #FFFFFF !important;
  background-color: #EBA039!important;
  border: 0;
  text-transform: uppercase;
  border-radius: 47px;
  width: 15rem;
  height: 2.5rem;
}
.Button-confirm-miscellaneous-Max {
  margin-bottom: 15px;
  font-family: 'Sweet Sans Pro';
  color: #FFFFFF !important;
  background-color: #EBA039!important;
  border: 0;
  text-transform: uppercase;
  border-radius: 47px;
  width: 15rem;
  height: 2.5rem;

}


.Cashier-status-resume {
  display: grid;
  margin-top: 10px;
  align-self: end;
  margin-left: 15px;
}

.Ticket-response-actions {
  display: flex;
  justify-content: center;
}

.Ticket-response-p {
  margin-top: 5px;
  margin-right: 20px;
}

.capsule-tabs-bottom {
  position: relative;
  top: 85px !important;
  background-color: #f5f5f5;
  margin-top: 30px;
  padding-inline: 3%;
  max-width: -webkit-min-content;
  max-width: min-content;
  z-index: 1;
}

.capsule-tabs-bottom-editing {
  position: relative;
  background-color: #f5f5f5;
  margin-top: 20px;
  max-width: -webkit-min-content;
  max-width: min-content;
}

.capsule-tabs-top {
  position: relative;
  background-color: #f7f7f7;
  margin-top: 0;
  max-width: 80vw;
}

.adm-capsule-tabs-tab {
  color: #6b6b6b;
  background-color: #ededed !important;
}

.adm-capsule-tabs-tab-active {
  height: 38px;
  border-width: 3px !important;
  color: #F5222D;
  background-color: #ededed !important;
  border: solid;
  border-width: 2px !important;
}

.Cashier-slider-dots {
  z-index: 1 !important;
  top: 265px !important;
}

.Cashier-search-button {
  visibility: hidden;
}

.ant-divider-horizontal {
  margin: 0 !important;
}

.Ticket-scroll-button {
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  position: fixed;
  visibility: visible;
  background-color: #70b96c;
}

.Cashier-item-combo {
  position: absolute;
  font-size: 1rem;
  text-align: center;
  bottom: 0;
  left: 25%;
  width: 50%;
  color: #7CB305;
}

@media screen and (max-width: 600px) {
  .Cashier-slider-dots {
    top: 155px !important;
  }
}

@media screen and (min-width: 601px) {
  .Cashier-item {
    width: 19%;
    margin-left: 0.8%;
  }

  .Cashier-category-item {
    width: 19%;
    height: 200px;
    margin-left: 0.8%;
  }

  .Cashier-item-list {
    padding-top: 40px;
    min-height: calc(89% - 146px);
  }

  .Cashier-item-list-editing {
    padding-top: 10px;
    min-height: calc(89% - 146px);
  }

  .Cashier-item-img {
    height: 21em;
  }
}

@media only screen and (max-width: 766px) {

  .Cashier-pay-btn {
    margin-bottom: 200px;
  }

  .capsule-tabs-bottom {
    margin-top: 60px !important;
  }

  .Cashier-item-list {
    padding-top: 65px;
    margin-top: 75px;
    min-height: calc(100vh - 285px);
  }

  .Cashier-item-list-editing {
    padding-block: 50px;
    min-height: calc(100vh - 285px);
  }

  .Cashier-search-button {
    z-index: 1;
    right: 20px;
    bottom: 20px;
    position: fixed;
    visibility: visible;
    background-color: #70b96c !important;
  }
}

input:not([type]), input[type=text]:not(.browser-default), input[type=password]:not(.browser-default), input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), input[type=search]:not(.browser-default), textarea.materialize-textarea {
  margin: 0;
  border: none;
}

input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  box-shadow: none;
  border-bottom: none;
}

.ant-carousel .slick-dots li button {
  background: #000;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #4982ff;
}

.ant-radio-button-wrapper {
  width: 100%;
  margin-bottom: 3px;
}

footer {
  bottom: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  height: 241px;
  padding: 15px;
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  margin-top: 46px;
  min-width: 1024px;
  text-align: center;
  align-items: center;
  line-height: 1.33337;
  letter-spacing: -.01em;
  background-color: #ffffff;
  border-top: 1px solid #e8e8e8;
}

.footer-content {
  margin: 0 auto;
  max-width: 980px;
  padding: 0 22px;
}

.footer-section {
  border-top: none;
}

.footer-shop {
  margin-bottom: 7px;
  padding-bottom: 8px;
  border-bottom: 1px solid #d2d2d7;
}

.footer-legal {
  position: relative;
  top: -3px;
  z-index: 1;
}

.footer-legal-copyright {
  margin-right: 30px;
  float: left;
  margin-top: 10px;
}

.footer-legal-links {
  margin-right: 30px;
  float: left;
  margin-top: 5px;
}

.footer-legal-link {
  border-right: 1px solid #d2d2d7;
  margin-right: 7px;
  padding-right: 10px;
  display: inline-block;
  margin-top: 5px;
  white-space: nowrap;
  color: #979898;
  text-decoration: none;
}

.footer-logos {
  float: right;
  margin-top: 5px;
  position: relative;
  top: -3px;
  z-index: 2;
  white-space: nowrap;
}

.card-img-footer {
  height: 40px;
}

.ant-input-group-addon {
  background-color: transparent;
}

html {
  scroll-behavior: smooth;
}

/* Layout */
main {
  max-width: 700px;
  margin: 3rem auto;
  padding: 0 1rem;
}

#scroll-to-bottom {
  font-weight: bold;
  color: green;
}

@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }

  .page {
    margin: 0;
    width: auto;
    width: initial;
    border: medium none currentColor;
    border: initial;
    min-height: 0;
    min-height: initial;
    box-shadow: none;
    box-shadow: initial;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    background: initial;
    border-radius: 0;
    border-radius: initial;
    page-break-after: always;
  }
}

@page {
  size: A4;
  margin: 0;
}

.StepsManager {
  width: 90%;
  margin-left: 5%;
  margin-bottom: 15px;
}

.StepsManager-Actions {
  margin: 15px;
  display: flex;
  justify-content: flex-end;
}

.Button-Pay-Card {
  clear: both;
  margin-right: 5%;
  margin-bottom: 15px;
  color: #FFFFFF !important;
}

.storePhone {
  color: #5491F5;
  transform: rotate(36deg);
  font-size: 1.3rem !important;
}

.storeLocation {
  color: #5491F5;
  font-size: 2rem !important;
}

.Cashier-modal-button {
  border: none;
  height: 2rem;
  color: white;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 20px;
  background-color: #70b96c;
}

.container-modal {
  overflow-scrolling: auto;
}

.modal-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ant-modal-title {
  margin: 0 0.5rem;
  font-weight: 600;
}

.Modal-Product-Description {
  font-weight: 600;
}

.modal-detail-container-img {
  display: flex;
  justify-content: center;
}

.modal-detail-container-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-detail-item-img {
  height: 12em;
  display: flex;
  color: silver;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.modal-detail-item-img i {
  font-size: 8rem;
}

.modal-detail-details {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal-detail-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 0.5rem;
  margin-bottom: 0.7rem;
}

.modal-detail-promoPrice {
  color: #9c9d9d;
  margin-bottom: 0.5rem;
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

.modal-detail-rate {
  margin-bottom: 0.5rem;
}

.ant-rate-star-first, .ant-rate-star-second {
  color: #d8d8d8;
}

.modal-favorite > span > svg {
  width: 25px;
  height: 25px;
  color: #d8d8d8;
  cursor: pointer;
}

.modal-favorite-selected span > svg {
  width: 25px;
  height: 25px;
  color: #fadb14;
  cursor: pointer;
}

.ant-list-split .ant-list-header {
  border-bottom: 1px solid #d8d8d8;
}

.modal-detail-container-rateAndPrice {
  display: flex;
  justify-content: space-between;
}

.modal-detail-container-combos {
  display: flex;
  padding-top: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.modal-detail-container-combos-text-container {
  display: flex;
  flex-direction: column;
}

.modal-detail-container-combos-title {
  background-color: white;
  font-size: 18px;
  font-weight: 600;
  margin: 0 !important;
}

.modal-detail-container-combos-subtitle {
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  color: #888888;
  margin: 0 !important;
}

.modal-detail-container-combos-down {
  color: #BFBFBF;
  font-size: 26px;
}

.modal-detail-combos-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 5px;
}

.modal-detail-combos-item-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.modal-detail-combos-item-title {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin: 0 !important;
}

.modal-detail-combos-circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #BFBFBF;

}

.Icon-Promotion-Text {
  display: block;
  font-size: 1.1rem;
  margin-left: 0.5rem;
  font-feature-settings: "tnum";
  font-variant: tabular-nums;
}

.Cashier-header-cart-modal-product {
  position: relative;
}

.Cashier-slider-dots-off > li {
  display: none !important;
}

.Cart-Icon-Outlined-Gift {
  top: 70%;
  left: 90%;
  z-index: 1;
  color: #FF0000;
  text-align: center;
  position: absolute;
}

.Modal-Icon-Outlined-Gift {
  top: 10%;
  left: 5%;
  z-index: 1;
  color: #FF0000;
  text-align: center;
  position: absolute;
}

.Ticket-container-price {
  position: relative;
}

.Ticket-Icon-Outlined-Gift {
  z-index: 1;
  color: #FF0000;
  text-align: center;
  position: absolute;
}

.Ticket-status-price {
  margin-left: 6px;
}

.Sort-Icon {
  color: #595959;
  margin-right: 0rem;
  font-size: 25px;
  z-index: 9999;
}

.filterMainContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 95% !important;
  max-width: 60vw !important;
  z-index: 1;
}

.Cashier-promo {
  max-height: 300px;
  margin-top: 4rem;
}

.ant-carousel .slick-slider .slick-track, .ant-carousel .slick-slider .slick-list {
  width: 100%;
}

.Cashier-item-carousel {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 1rem;
}

.Cashier-item-carousel-promotions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Cashier-carousel-promotion {
  display: flex;
  height: 343px !important;
  margin: 2rem;
  width: 334px;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  border: 1px solid #D9D9D9;
  box-shadow: 0 4px 4px 0 #00000040;
}

.Cashier-carousel-promotion-text {
  color: #F5222D;
  font-weight: 900;
  font-size: 2.8rem;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  font-family: Roboto, sans-serif;
}

.Icon-Outlined-Gift-carousel {
  display: flex;
  color: #F5222D;
  margin-bottom: 1.2rem;
  justify-content: center;
}

.Icon-Outlined-Gift-carousel > span {
  font-size: 3.2rem !important;
}

.Icon-Outlined-Gift-card {
  width: 20%;
  color: #FF0000;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 2% !important;
  left: 2% !important;
}

.Cashier-slider-dots {
  display: flex;
  margin-top: 0.6rem;
}

.ant-carousel .slick-dots-bottom {
  bottom: 0;
  position: static;
}

.Cashier-promotion {
  display: grid;
  grid-gap: 0.8rem;
  margin: 0 0.9rem;
  margin-top: 11rem !important;
  grid-template-columns: repeat(2, 1fr);
  overflow-x: scroll;
}

.Cashier-promoton-items-container {
  width: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.Cashier-promoton-items-container::-webkit-scrollbar {
  display: none;
}


.Cashier-promotion-editing {
  display: flex;
  width: 100%;
  height: 180px;
  overflow: hidden;
  align-items: center;
}

.Cashier-card-promo {
  width: 173px;
  display: flex;
  height: 190px;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  border: 1px solid #D9D9D9;
  margin-top: 4rem;
}

.Cashier-card-promo > div {
  padding: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.Icon-Outlined-Gift-promo {
  display: flex;
  color: #F5222D;
  margin-bottom: 1.2rem;
  justify-content: center;
}

.Cashier-promotion-text {
  color: #F5222D;
  font-weight: 900;
  font-size: 1.7rem;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  font-family: Roboto, sans-serif;
}

.Cashier-container-card-promo {
  width: 142px;
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.Cashier-container-item {
  margin-top: 7rem;
}

.Cashier-promotion-empty {
  min-height: 300px;
}

.breadcrumb-promotion {
  color: #6b6b6b;
  font-size: smaller;
}

.adm-scroll-mask {
  background: transparent !important;
}


.adm-capsule-tabs-header {
  max-width: 92vw !important;
  padding: 5px !important;
  border-width: 0 !important;
  background-color: #f7f7f7 !important;
}

.Cashier-container-promoAndFilter {
  justify-content: flex-end;
  display: flex;
}
.tdstatus {
  width: 82px !important;
  max-width: 82px !important;
  min-width: 82px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Sweet Sans Pro";
  white-space: nowrap;
}
@media only screen and (max-width: 600px) {
  .Filter-Circle-Button-Container {
    width: 86% !important;
  }

  .Cashier-breadcrumb-left-container {
    width: 95% !important;
  }

  .Icon-Outlined-Gift {
    top: 1%;
  }

  .Icon-Outlined-Gift .anticon {
    font-size: 30px !important;
  }

  .Icon-Promotion-Text {
    font-size: 1rem;
    margin-top: -4px;
    margin-left: 0.2rem;
    font-family: Roboto, sans-serif;
  }

  .Ticket-status-price {
    margin-left: 24px;
  }

  .thstatus {
    width: 82px !important;
    max-width: 82px !important;
    min-width: 82px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tdstatus {
    width: 82px !important;
    max-width: 82px !important;
    min-width: 82px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Sweet Sans Pro";
    white-space: nowrap;
  }

  .transaction-status-table {
    overflow-x: scroll;
    flex: 1 1;
    display: flex;
    flex-direction: column;

  }

  .Status-Container {
    max-width: 100vw !important;
    margin-inline: 5%;

  }
}

@media only screen and (min-width: 374px) {
  .Cashier-container-card-promo {
    width: 170px;
  }
}

@media only screen and (min-width: 425px) {
  .adm-capsule-tabs-header {
    width: 300px;
  }

  .Cashier-container-card-promo {
    width: 200px;
  }
}

@media only screen and (min-width: 480px) {
  .Cashier-promotion {
    grid-template-columns: repeat(3, 1fr);
  }

  .Cashier-promotion-text {
    font-size: 1.6rem;
  }

  .Cashier-promo {
    max-height: 330px;
  }

  .adm-capsule-tabs-header {
    width: 400px;
  }
}

@media only screen and (min-height: 741px) {
  .ant-image {
    width: 320px !important;
  }
}

@media only screen and (min-width: 769px) {
  .modal-detail-container-img > div > img {
    height: 80%;
    object-fit: cover;
  }

  .ant-image {
    width: 350px !important;
  }

  .Cashier-carousel-promotion-text {
    font-size: 3rem;
  }

  .Cashier-promotion-empty {
    min-height: 535px;
  }

  .adm-capsule-tabs-header {
    width: 100%;
  }

  .ant-breadcrumb {
    width: 100%;
  }

  .Cashier-slider-dots-promo {
    margin-block: 0.5rem !important;
  }
}

@media only screen and (min-width: 1025px) {
  .modal-detail-container-img > div > img {
    height: 80%;
    object-fit: cover;
  }

  .ant-image {
    width: 360px !important;
  }

  .modal-favorite > span > svg:hover {
    color: #fadb14;
  }
}

.status-footer-container {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
}

.CashierRedirectButton {
  margin-block: 2rem;
  align-self: flex-end;
  border: 1px solid #0f9d58;
  color: #0f9d58;
  background-color: #FFFFFF;
  cursor: pointer;
  border-radius: 8px;
  height: 1.8rem;
}
.shipment-form {
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    background-color: hsla(0, 0%, 100%, .9490196078431372);
}

.shipment-form-input {
    padding-bottom: 8px !important;
    border-bottom: 1px solid  !important;
    color: #1890ff;
}

.shipment-form-input-phone {
    border-bottom: 1px solid  !important;
    color: #1890ff;
}

.shipment-form-input-phone > div > input {
    border: none !important;
    color: #000000;
}

.shipment-form .shipment-form-input__error {
    border-bottom: 1px solid #ef9797 !important;
}

.flag-dropdown {
    border: none !important;
    background: none !important;
}

.shipment-form > div > div > div > div > span > span > span {
    border: none !important;
}

.shipment-form-inputSelect .ant-select-selector {
    border: none !important;
}

.input-error {
    margin-top: 2px !important;
}

.input-error-phone {
    margin-top: -18px !important;
}

.shipment-form-input-margin {
    margin-top: 4.9rem !important;
    padding-bottom: 8px !important;
    border-bottom: 1px solid  !important;
    color: #1890ff;
}

@media only screen and (min-width: 767px) {
    .shipment-form {
        margin: 0 auto;
        max-width: 70%;
    }

    .input-error-phone {
        margin-top: -18px !important;
    }

    .input-error {
        margin-top: -17px !important;
    }
}
.Content-address {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 2.5rem 0;
}

.Content-address-title {
    font-size: 2.8rem;
    text-transform: capitalize;
}

.Content-address-list {
    display: flex;
    margin: 1.5rem 0;
    flex-direction: column;
    justify-content: center;
}

.Content-address-list-subTitle {
    display: flex;
    font-size: 1.7rem;
    justify-content: center;
}

.ant-list-split .ant-list-item {
    border-bottom: 1px solid #1890ff;
}

.Content-address-card-body {
    font-size: 1rem;
    text-transform: capitalize;
    width: 60%!important;
    max-width: 60%!important;
    min-width: 60%!important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Content-address-card-button {
    height: 50px;
    width: 274px;
    border: 1px solid #00000040;
}

.Content-address > button {
    border-color: #1890ff !important;
    background-color: #1890ff !important;
}

.Content-address-card {
    height: 50px;
    width: 274px;
    display: flex;
    margin: 1rem 0;
    cursor: pointer;
    border-radius: 4px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.Content-address-card > span {
    font-size: 0.8rem;
}

.Content-address-card:hover {
    border: 1px solid #91C48A;
}

.ant-card-bordered {
    height: 120px;
    margin: 0.2rem 0.5rem;
    border: 1px solid #f0f0f0;
}

.Content-address > button:hover {
    color: #1890ff !important;
    border: 1px solid #1890ff !important;
    background-color: #ffffff !important;
}

@media screen and (min-width: 376px) {
    .Content-address-card {
        width: 370px;
    }
    .Content-address-card > span {
        font-size: 1rem;
    }
    .Content-address-card-button {
        width: 370px;
    }
}

@media screen and (min-width: 769px) {
    .Content-address-card {
        width: 410px;
    }
    .Content-address-card > span {
        font-size: 1rem;
    }
    .Content-address-card-button {
        width: 410px;
    }
}

.Cashier-header-addressList {
  display: flex;
  justify-content: left;
  height: 2rem;
  overflow: visible;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;

}
p{
  margin:0!important;
}

.showAddress-select-Option-none{
  display: none;
}
.Show-Address-header{
  display: flex;
  background-color: #f7f7f7;
  flex-direction: row;
  padding-inline: 2vw;
  justify-content: left!important;
  align-items: center!important;

}

.Show-Address-Title{
  display: flex;
  font-size: 1rem;
  height: 2rem;
  font-family: 'Sweet Sans Pro';
  color: #137CC1;
  line-height: 2rem;
}

select::-webkit-scrollbar {
  display: none;
}

.Show-Address-dropIcon{
  font-size: 1rem;
  margin-left: 0.6rem;
  color: #137CC1;
}

.Show-Address-content{
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  align-items: center;
  z-index: 999;
  border-radius: 0 0 17px 17px;
  position: relative;
  margin-top: -0.1rem;
  padding-top: 0.7rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.06);
  min-height:-webkit-max-content;
  min-height:max-content;
}
.Show-Address-buttons{
  display: flex;
  flex-direction: row;
  width: 100vw;
  z-index: 999999999;
  justify-content: space-between;
  padding-inline: 15vw;
  }
.Show-Address-button{
  display: flex;
  min-width: 8rem!important;
  justify-content: center;  z-index: 999999999;
  font-weight: 400;
  height: 2rem;
  border-radius: 47px;
  font-size: 1.2rem;
  background-color: #137CC1;
  color: #FFFFFF;
  font-family: 'Sweet Sans Pro';
}
.Show-Address-button-active {
  display: flex;
  min-width: 8rem!important;
  justify-content: center;
  font-weight: 400;
  height: 2rem;
  border-radius: 47px;
  font-size: 1.2rem;
  background-color: #FFFFFF;
  color: #137CC1;
  border: 1px solid #137CC1;
  font-family: 'Sweet Sans Pro';
}

select {
  /* Reset Select */

  outline: 0;
  border: 0;
  box-shadow: none;
  /* Personalize */


  max-width: 10rem !important;
  color: #fff;
  cursor: pointer;
}
option{
  cursor: pointer;
}
/* Custom Select wrapper */
.showAddress-select {
  position: relative;
  border-radius: 12px!important;
  font-family: 'Sweet Sans Pro';
  text-transform: capitalize;
  display: flex;
  max-width: 12rem !important;
  text-align: left;
  height: 2.5rem!important;
  font-size: 1.1rem;
  line-height: 1rem;
  border-radius: .25em;
  background-color: white;
  border: 1px solid #137CC1;
  color: #137CC1;
  margin-block: 0.5rem;
  overflow: hidden;
}
/* Arrow */
.showAddress-select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  max-width: 8rem !important;
  padding: 1em;
  background-color: red;
  transition: .25s all ease;
  pointer-events: none;
}
/* Transition */
.showAddress-select:hover::after {
  color: #f39c12;
}

.Address-Switch{
  background-color: #137CC1;
}


.addAddress-Button-Container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.Cashier-header-addressList-Container{
  padding-bolck: 2px;
  margin-bottom: 0.5rem;
  margin-top: 1px;
  display: flex;
  flex-direction: column;

}



.Cashier-header-button-add {
  margin-bottom: 0.43rem;
  border: none !important;
  color: #00704A !important;
}

.Cashier-header-button-add:hover {
  background-color: #00704A !important;
}

.pickup-form{
  align-self: baseline;
  background-color: #FFFFFF;
  z-index: 3;
}

.pac-container{
  z-index: 999999;
}

.shippmentContainer{
  top:0;
  z-index: 999;
  width: 100%;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5vh;
  height: 150%!important;
  position: absolute;
  background-color: rgba(107, 107, 107, 0.35);
}

.AddAddress-form{
  height: 90vh;
  width: 90vw;
  background-color: #FFFFFF;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding-inline: 5vw;
}
.ant-notification-notice ant-notification-notice-info ant-notification-notice-closable{
  z-index: 9999;
}
.map {
  width: 100%;
  height: 350px;
  margin-block: 10px;
  margin-bottom: 3rem;
}

.AddAddress-form-input-margin{
  width: 100%;
  background-color: #4ba731;
  height: 1.8rem;
}

.AddAddress-Form-phone-input{
  border: 1px solid #000;
}

.shipment-form-input{
  background-color: #4a82ff;
}

.Show-Address-storeAlert{
  position: absolute;
  box-shadow: 0 2px 4px rgba(1, 1, 1, 0.48), 0 2px 2px rgba(0, 0, 0, 0.06);
  width: 80vw;
  border-radius: 8px;
  height: 20vh;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99999;
  top:10vh;
  left: 10vw;
  justify-content: center;
}
.Show-Address-storeAlert-close{
  color: #4a82ff;
  position: absolute;
  right: 1rem;
  top:1rem
}
.Show-Address-storeAlert-text{
  font-family: 'Sweet Sans Pro';
  color: rgba(0, 0, 0, 0.85);
  width: 60%;
  text-align: center;
  line-height: 1rem;
}
.Show-Address-storeAlert-Button{
  display: flex;
  min-width: 5rem!important;
  justify-content: center;
  font-weight: 400;
  height: 1.6rem;
  align-items: center;
  border-radius: 12px;
  font-size: 1rem;
  background-color: #FFFFFF;
  color: #137CC1;
  border: 1px solid #137CC1;
  font-family: 'Sweet Sans Pro';
  margin-top: 1rem;
}
.showAddress-select-Option{
  width: 100%;
  background-color: #FFFFFF;
  height: 2rem;
}

.AddAddress-product-Purchase {
  text-align: center;
  display: flex;
  align-self: center;
  font-size: 1.1rem;
  align-items: center;
  font-family: 'Sweet Sans Pro';
  background-color: #137CC1;
  margin: 0.25rem 0px 0px 0px;
  width: 6rem;
  height: 2rem;
  justify-content: center;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 47px;
  border: 0;
}
.AddAddress-product-Purchase:disabled{
  background-color: grey;
}


@media only screen and (min-width: 800px) {
  .Cashier-header-addressList {

    width: 25vw!important;


  }
  .Show-Address-storeAlert{
    width: 25vw;
    left: 1rem;
    top: 8vh;
  }
  .Show-Address-header{
    width: 25vw!important;
  }
  .Show-Address-content{
    width: 25vw!important;
  }
  .Show-Address-buttons{
    width: 25vw!important;
    padding-inline: 1rem;
  }
}
.Form-shipmentCO {
  margin: 0 1rem;
  padding: 0 1rem;
}

.Form-input-error {
  margin-top: -33px;
}

@media screen and (min-width: 768px){
  .Form-shipmentCO {
    margin: 0 5rem;
  }
}
.skeletonPayU{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.skeletonPrice{
  display: flex;
  justify-content: center;
  margin: 16px 0;
}
.skeletonPrice > span{
  margin-top: 8px !important;
  height: 44px !important;
  width: 225px !important;
}
.skeletonInputSteps{
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}
.skeletonInputSteps > span{
  width: 90% !important;
}
.skeletonInput{
  display: flex;
  justify-content: center;
}
.skeletonInput > span{
  width: 90% !important;
  height: 500px !important;
}
.skeletonButton{
  display: flex;
  justify-content: right;
  margin: 8px 25px 10px 5px;
}
.skeletonButton > span {
  height: 36px !important;
  width: 64px !important;
}
@media only screen and (max-width: 766px) {
  .skeletonInput > span {
    height: 440px !important;
  }
  .skeletonButton{
    margin: 8px;
  }
}
.skeletonToroso{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.skeletonPrice{
  display: flex;
  justify-content: center;
  margin: 16px 0;
}
.skeletonPrice > span{
  margin-top: 8px !important;
  height: 44px !important;
  width: 225px !important;
}
.skeletonInput{
  display: flex;
  justify-content: center;
}
.skeletonInput > span{
  width: 90% !important;
  height: 550px !important;
}
@media only screen and (max-width: 766px) {
  .skeletonInput > span {
    height: 600px !important;
  }
}
.skeletonPayUpse{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.skeletonPrice{
  display: flex;
  justify-content: center;
  margin: 16px 0;
}
.skeletonPrice > span{
  margin-top: 8px !important;
  height: 44px !important;
  width: 225px !important;
}
.skeletonInputLogo{
  border-radius: 50%;
}
.skeletonInputRemember{
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}
.skeletonInputRemember > span{
  width: 80% !important;
  height: 20px !important;
}
.skeletonInputAdvices{
  margin-bottom: 2px;
}
.skeletonInputAdvices > span{
  width: 90% !important;
  height: 110px !important;
}
.skeletonForm > span{
  width: 90% !important;
  height: 550px !important;
}
.skeletonInputs{
  display: flex;
  justify-content: center;
}
.skeletonInput > span{
  width: 90% !important;
  height: 500px !important;
}
.skeletonButton{
  display: flex;
  justify-content: right;
  margin: 8px 25px 10px 5px;
}
.skeletonButton > span {
  height: 36px !important;
  width: 64px !important;
}
@media only screen and (max-width: 766px) {
  .skeletonInput > span {
    height: 440px !important;
  }
  .skeletonButton{
    margin: 8px;
  }
}

.skeletonBillingInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.skeletonBilling {
    width: 40%;
    display: flex;
    justify-content: center;
}

.skeletonBillingPrice {
    width: 40%;
    display: flex;
    margin: 1rem 0;
    justify-content: center;
}

.skeletonBillingForm > span {
    margin-top: 1rem;
    width: 300px !important;
    height: 450px !important;
}

@media only screen and (min-width: 768px) {
    .skeletonBillingForm > span{
        width: 700px !important;
        height: 450px !important;
    }
}

@media only screen and (min-width: 1024px) {
    .skeletonBillingForm > span{
        width: 1000px !important;
        height: 450px !important;
    }
}
.skeletonMiscellaneuos{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.skeletonPrice{
  display: flex;
  justify-content: center;
  margin: 16px 0;
}
.skeletonPrice > span{
  margin-top: 8px !important;
  height: 44px !important;
  width: 225px !important;
}
.skeletonInputs{
  display: flex;
  justify-content: center;
  align-content: center;
}
.skeletonInputDescription{
  margin-bottom: 8px;
}
.skeletonInputDescription > span{
  width: 90% !important;
}
.skeletonInputResume{
  margin: 16px 0;
}
.skeletonInputResume > span{
  width: 225px !important;
}
.skeletonInputOrders > span{
  width: 90% !important;
  height: 80px !important;
}
.skeletonInputOrders > span{
  width: 90% !important;
}
.skeletonInputTotal{
  margin: 16px 0
}
.skeletonInputTotal > span{
  width: 300px !important;
}
.skeletonButtonConfirm > span{
  border-radius: 4px;
  height: 36px !important;
  width: 170px !important;
}
.skeletonButtons{
  margin: 8px 0;
  display: flex;
  justify-content: center;
}
.skeletonButtons > span {
  height: 25px !important;
  width: 85px !important;
}

@media only screen and (max-width: 766px) {
  .skeletonInputDescription > span{
    height: 155px !important;
  }
  .skeletonInputOrders > span{
    height: 160px !important;
  }
}
