.shipment-form {
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    background-color: hsla(0, 0%, 100%, .9490196078431372);
}

.shipment-form-input {
    padding-bottom: 8px !important;
    border-bottom: 1px solid  !important;
    color: #1890ff;
}

.shipment-form-input-phone {
    border-bottom: 1px solid  !important;
    color: #1890ff;
}

.shipment-form-input-phone > div > input {
    border: none !important;
    color: #000000;
}

.shipment-form .shipment-form-input__error {
    border-bottom: 1px solid #ef9797 !important;
}

.flag-dropdown {
    border: none !important;
    background: none !important;
}

.shipment-form > div > div > div > div > span > span > span {
    border: none !important;
}

.shipment-form-inputSelect .ant-select-selector {
    border: none !important;
}

.input-error {
    margin-top: 2px !important;
}

.input-error-phone {
    margin-top: -18px !important;
}

.shipment-form-input-margin {
    margin-top: 4.9rem !important;
    padding-bottom: 8px !important;
    border-bottom: 1px solid  !important;
    color: #1890ff;
}

@media only screen and (min-width: 767px) {
    .shipment-form {
        margin: 0 auto;
        max-width: 70%;
    }

    .input-error-phone {
        margin-top: -18px !important;
    }

    .input-error {
        margin-top: -17px !important;
    }
}