.PurchaseProduct {
    margin: 0 0 1.2rem 0!important;
    padding: 0!important;
    display: flex;
    height: 28px!important;
    width: 16rem!important;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 30px;
    align-items: center;
    margin-bottom: 1.2rem;
    justify-content: center;
    background-color: #70b96c;
    background-clip: content-box;
}

.PurchaseProduct i {
    font-size: 2rem !important;
}

.ant-modal-footer {
    display: flex !important;
    justify-content: center !important;
}

.PurchaseProduct__unavailable {
    margin: 0 0 1.2rem 0!important;
    padding: 0!important;
    display: flex;
    height: 28px!important;
    width: 16rem!important;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 30px;
    align-items: center;
    margin-bottom: 1.2rem;
    justify-content: center;
    background-color: silver;
    background-clip: content-box;
}
